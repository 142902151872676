﻿html,
body {
  height: 100%;

  @include responsive-custom(0, 1020px) {
    &.no-scroll {
      overflow: hidden;
    }
  }
}

main {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  @include MyriadPro;
}

a {
  text-decoration: none;
  color: $cadet-blue;

  &:hover,
  &:visited,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

.o-layout {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 40px;

  @include responsive-custom(0, 1020px) {
    padding: 0 20px;
  }
}

.main-container {
  position: relative;
  z-index: 2;
  background-color: #ffffff;

  @include responsive-custom(0, 1020px) {
    margin-bottom: 0;
  }
  &.no-scroll {
    margin-bottom: 0;
    height: 100vh;
    overflow: hidden;
  }
}
