﻿body {
    padding-top: 55px;
}

/* Wrapping element */
/* Set some basic padding to keep content from hitting the edges */
.body-content {
    padding-left: 15px;
    padding-right: 15px;
}

/* Set widths on the form inputs since otherwise they're 100% wide */
input,
select,
textarea {
    max-width: 280px;
}

/* Carousel */
.carousel-caption p {
    font-size: 20px;
    line-height: 1.4;
}

/* buttons and links extension to use brackets: [ click me ] */
.btn-bracketed::before {
    display: inline-block;
    content: "[";
    padding-right: 0.5em;
}

.btn-bracketed::after {
    display: inline-block;
    content: "]";
    padding-left: 0.5em;
}
  
/* Make .svg files in the carousel display properly in older browsers */
.carousel-inner .item img[src$=".svg"] {
    width: 100%;
}

/* Hide/rearrange for smaller screens */
@media screen and (max-width: 767px) {
    /* Hide captions */
    .carousel-caption {
        display: none
    }
}

/*IS4 CSS*/
.validation-summary-valid {
    display: none;
}

.logged-out iframe {
    display: none;
    width: 0;
    height: 0;
}

.page-consent .client-logo {
    float: left;
}

.page-consent .client-logo img {
    width: 80px;
    height: 80px;
}

.page-consent .consent-buttons {
    margin-top: 25px;
}

.page-consent .consent-form .consent-scopecheck {
    display: inline-block;
    margin-right: 5px;
}

.page-consent .consent-form .consent-description {
    margin-left: 25px;
}

.page-consent .consent-form .consent-description label {
    font-weight: normal;
}

.page-consent .consent-form .consent-remember {
    padding-left: 16px;
}

.site-container {
    min-height: 100%;
    margin-bottom: -174px;
}

.form-control {
    border-radius: 0;
}

.faq-link {
    margin-top: 46px;
    display: block;
    float: left;
}

.label--large {
    display: inline-block;
    font-size: 1em;
    padding: 0.5em 0.8em;
}

.u-mt-1 {
    margin-top: 1em !important;
}

.u-mt-2 {
    margin-top: 2em !important;
}

.u-mt-3 {
    margin-top: 3em !important;
}

.u-mb-1 {
    margin-bottom: 1em !important;
}

.u-mb-2 {
    margin-bottom: 2em !important;
}

.u-mb-3 {
    margin-bottom: 3em !important;
}

.u-mb-10px {
    margin-bottom: 10px !important;
}

.u-ml-0 {
    margin-left: 0 !important;
}

ul.u-no-list-style {
    list-style-type: none;
}

.u-width-100 {
    width: 100% !important;
}

.u-fs-16px {
    font-size: 16px;
}


.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.alert-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.alert-danger {
    color: #b94a48;
    background-color: #f2dede;
    border-color: #eed3d7;
}

.u-fl {
    float: left;
}

.u-fr {
    float: right;

    &.u-fr--xs-left {
        @include responsive-custom(0,360px) {
            float: left;
        }
    }
}


.btn-block {
    display: block;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
}

.u-reset-margin-padding {
    margin: auto;
    padding: initial;
}
