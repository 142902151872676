$imagePath: "../img";
.noa-form {
    label {
        font-size: 16px;
        margin-bottom: 5px;
        font-weight: normal;
    }
	input, textarea, select {
		&:not([type="submit"]),
		&:not([type="radio"]),
		&:not([type="checkbox"]){
			border-color: $gray-form-border;
			border-width: 1px;
            border-radius: 0;
    		border-style: solid;
			font-size: 14px;
            -webkit-appearance: normal;
            margin-bottom: 15px;
			&:not(textarea){
				height: 40px;
			}
			&:focus {
				font-style: normal;
				border-color: $highlight-color;
			}
			::-webkit-input-placeholder {
			   color: $gray-dark1;
			}
			:-moz-placeholder { 
			   color: $gray-dark1;  
			}
			::-moz-placeholder { 
			   color: $gray-dark1;  
			}

			:-ms-input-placeholder {  
			   color: $gray-dark1;  
			   
			}
			&.small-input {
				width: 119px;
				padding: 0 15px;
				& + span {
					margin: 0 10px;
				}
			}
		}
	}
    select {
        color: rgba(119,119,119,0.8);
        -webkit-appearance: normal;
    }
	.has-error {
        margin-top: -10px;
        display: block;
		input.form-control, textarea.form-control {
		    &:not([type="submit"]),
			&:not([type="radio"]),
			&:not([type="checkbox"]){
		    	border-color: $error-color;
		    }
		}
		.error-msg {
            display: block;

          .has-error {
            display: block;
			color: $error-color;
            margin-top: -10px;
            }
		}
	}
	textarea {
		height: 150px;
	}
	ol {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			margin-top: 20px;
			&:first-child {
				margin: 0;
			}
		}
	}
    .validation-summary-valid {
        margin-left: 15px;
    }
	.checkbox-button {
	  position: relative;
	  cursor: pointer;
	  padding: 0 0 0 32px;
	  line-height: 42px;
	  float: left;
      width: 100%;
	  input[type="checkbox"]  {
	    display: none;
	  } 
      input[type="checkbox"]:checked + label {
            background: #3d6fa5;
            color: white;
            border-bottom: 1px solid white;
            .count  {
                color: white;
	        } 
            span {
                color: white;
            }
      } 
      &.has-error{
          .error-msg{
              display: block;
          }
      }
      .error-msg {
          display: none;
      }
	}
	.radio-button {
	  position: relative;
	  display: table-cell;
	  vertical-align: middle;
	  cursor: pointer;
	  padding: 0 0 0 40px;
	  line-height: 30px;
	  &:last-child  input[type="radio"] + label {
	  	margin-right: 0;
	  }
	  input[type="radio"]  {
	    display: none;
	  }
	  input[type="radio"] + label {
	    cursor: pointer;
	  	font-weight: normal;
	  }
	  input[type="radio"] + label:before {
	    content:'';
	    background: $white;
	    border: 1px solid $gray-light4;
	    width: 30px;
	    height: 30px;
	    position: absolute;
	    left:0;
	    top: 1px;
	    padding:0;
	    border-radius: 50%;
	  }
	  input[type="radio"] + label:after {
	  	background: $white;
	    display: block;
	    content: '';
	    left: 10px;
	    top: 11px;
	    font-family: initial;
	    width: 10px;
	    height: 10px;
	    position: absolute;
	    line-height: 0;
	    padding:0;
	    border-radius: 50%;
	  }
	  input[type="radio"]:checked + label:after {
	    background: $highlight-color;
	  }
	}
	.box {
		padding: 20px;
		margin-bottom: 40px;
        @include responsive(screen-from-tablet){
            padding: 20px 40px;
        }
		&.error {
			background: $error-color;
			color: $white;
            @include MyriadProBold;
			font-size: 14px;
            position: relative;
            ul{
                margin-bottom: 0;
            }
		}
	}
    .center-align {
        text-align: center;
        .noa-btn {
            margin: 28px auto 0 auto;
            @include responsive(screen-phone) {
                margin-top: 5px;
            }
        }
    }
}
.error-msg {
    color: $error-color;;
}
.search-toggle {
    background: white;
    border: none;
    height: 53px;
    border-left: 1px solid $highlight-color;
    float: right;
    width: 50px;
    position: relative;
    cursor:pointer;
    display: block;
    &:after {
        content: "";
        background: url(../img/layout/icons/svg/search.svg) no-repeat top left;
        background-size: 22px;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 22px;
        height: 22px;
    }
}
.noa-search {
    position: relative;
      &:before {
        background: url(../img/layout/icons/svg/search.svg) no-repeat top left;
        display: block;
        width: 16px;
        height: 16px;
        @include ab-center;
        left: 24px;
        right: auto;
        top: 10px;
        z-index: 3;
        @include responsive(screen-desktop) {
            top: 2px;
            left: 8px;
        }
    }
    &.mobile {
        display: none;
        position: absolute;
        top: 53px;
        left: 0;
        background: white;
        height: 64px;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        border-bottom: 1px solid $highlight-color;
        border-top: 1px solid $highlight-color;
        &.open {
            display: block;
        }
        input[type=text] {
            margin-top: 20px;
            width: 100%;
        }
        .search-submit {
            margin-top: 20px;
            width: 10%;
        }
    }
	.search-submit {
        background: $highlight-color;
		height: 28px;
		width: 28px;
		text-align: left;
		pointer-events: auto;
		cursor: pointer;
        position: relative;
        float: left;
        border: 1px solid $highlight-color;
        border-left: 0;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        &:after {
            content: "";
            background: url('#{$imagePath}/layout/icons/search-small.png') no-repeat 0 0;
            position: absolute;
            right: 0;
            bottom: 0;
            top: 0;
            left: 0;
            margin: auto;
            width: 18px;
            height: 18px;
        }
        &:hover {
            background: black;
        }
	}
	input[type=text]{
		border: 1px solid #ccc;
        border-radius: 0;
		height: 32px;
        color: $black;
        font-size: 14px;
        padding-left: 30px;

        float: left;
        width: 234px;
        @include MyriadPro;
	}
	&.form-horizontal .control-label {
		font: 17px/1.1 sans-serif;
		text-transform: uppercase;
		font-weight: normal;
		text-align: right;
		line-height: 31px;
	}
	.form-group {
		margin: 0;
        position: relative;
        z-index: 2;
	}  
}
.toggle-trigger {
    position: relative;
    width: 100%;
    cursor: pointer;
    &:after {
        content: "";
        position: absolute;
        right: 0;
        margin: auto;
        top: 0;
        bottom: 0;
        background: url(../img/layout/icons/arrow-down.png);
        width: 21px;
        height: 12px;
    }
    &.toggled {
        &:after {
            background: url(../img/layout/icons/arrow-up.png);
        }
    }
}
.g-recaptcha {
    @include responsive(screen-phone) {
       transform:scale(0.90); 
       transform-origin:0 0;
    }
}
.testaccount .g-recaptcha {
    @include responsive(screen-phone) {
       transform:scale(0.80); 
       transform-origin:0 0;
    }
}
.create-account {
    p {
        margin-bottom: 0;
        font-size: 16px;
    }
    h5 {
        @include MyriadProBold;
    }
}

.c-search {
    position: relative;
    &:before {
        background: url(../img/layout/icons/svg/search.svg) no-repeat top left;
        display: block;
        width: 20px;
        height: 20px;
        @include ab-center;
        right: auto;
        left: 10px;
        @include responsive(screen-from-tablet) {
            width: 40px;
            height: 40px;
            left: 21px;
        }
    }
    .form-control {
        border: 1px solid #ccc;
        border-radius: 0;
        color: #7b7b7b;
        height: 48px;
        width: 100%;
        font-size: 18px;
        padding-left: 40px;
        @include responsive(screen-from-tablet) {
            font-size: 21px;
            height: 84px;
            padding-left: 84px;
        }
        &::-webkit-input-placeholder,  &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
            color: #7b7b7b !important;
        }
    }
    .search-results {
        top: 48px;
        border: 1px solid #ccc;
        border-top: 0;
        @include responsive(screen-from-tablet) {
            top: 84px;
        }
    }
}
 .search-results {
    display: none;
    background: white;
    border-bottom: 1px solid #ccc;
    position: absolute;
    left: 0;
    top: 83px;
    width: 100%;
    z-index: 2;
    @include responsive(screen-desktop) {
        border: 1px solid #ccc;
    }
    a {
        color: #393d40;
        text-decoration: none;
        display: block;
        line-height: 35px;
        font-size: 16px;
        padding: 4px 12px;
        transition: all .3s;
        &:hover {
            background: #f6f8f8;
        }
    }   
}

 @media screen and (max-width: 1020px)
 {
    .search-results.js-main-search-results {
        top: 116px;
    }
 }