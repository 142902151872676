.user-signin.user-login, .user-signin.user-login .noa-navbar {
    background: $gray-light2;
}

.user-signin {
    .site-container {
        min-height: calc(100% - 100px);
    }
}
.tile {
    margin-bottom: 20px;
    @include responsive(screen-phone) {
        margin-bottom: 0;
    }
    &.first {
        margin-bottom: 20px;
    }

    &.login {
        background: $search-bar-color;
        border-color: $gray-light5;

        h3 {
            color: $white;
        }
    }

    &.terminate {
        h5 {
            margin: 10px 0;
        }
    }

    &.user-screen {
        @include responsive(screen-from-tablet) {
            padding: 40px 100px 60px 140px;
        }

        .box {
            margin: -40px -100px 40px -140px;
        }

        .row + .row {
            margin-top: 20px;

            &:last-child {
                margin-top: 40px;
            }
        }

        p {
            margin-bottom: 40px;
        }
    }
    .user-img {
        width: 148px;
        height: 148px;
        position: relative;
        z-index: 2;
        box-shadow: 4px 3px 7px 0px rgba(0,0,0,0.14);
        border: 8px solid white;
        @include responsive(screen-phone) {
            margin-bottom: 30px;
        }
        figure {
            background: $gray-light2;
            z-index: 2;
            img {
                max-width: 75%;
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }
        }
    }
    .gender {
        label {
            display: block;
            margin-bottom: 0;
        }
    }
    .add-btn {
        @include ab-center;
        top: auto;
        left: auto;
        width: 25px;
        height: 25px;
        background: white;
        z-index: 5;
        &:after {
            content: "";
            background: url(../img/layout/icons/profile-pic-edit.png);
            width: 15px;
            height: 14px;
            position: absolute;
            right: 0;
            bottom: 0;
            left: 1px;
            top: -1px;
            margin: auto;
        }
    }

    input[type=file] {
        display: none;
    }
    .row {
        font-size: 0;
        > * {
            display: inline-block;
            vertical-align: middle;
            float: none;
            font-size: 14px;
            position: relative;
            margin-bottom: 20px;
            @include responsive(screen-phone) {
                margin-top: 10px;
                margin-bottom: 0;
            }
        }
    }
    .error-msg {
        position: absolute;
    }
    .noa-btn.btn.btn-default.buy {
        padding: 0 56px;
    }
    .radio-button {
        display: inline-block;

        & + .radio-button {
            margin-left: 40px;
        }
    }
    .box {
        margin: -20px -20px 20px;

        @include responsive(screen-from-tablet) {
            margin: -40px -40px 40px;
        }
    }
    .highlight-wrapper {
        display: inline-block;
        vertical-align: middle;
        width: 130px;
        margin-right: 20px;
    }
    .forgotten-password {
        font: 16px/1.1 sans-serif;
        color: $white;
        text-decoration: none;
    }
    &.subscription {
        .radio-button {
            width: 100%;
            float: left;
            margin-top: 10px;
            margin-left: 0;
            &:first-child {
                margin-top: 0;
            }
        }
    }
}
.disclaimer {
    .checkbox-button {
        display: inline-block;
    }
}
