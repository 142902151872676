﻿.home {
    .scrollable-content {
        margin-top: 0;
        @include responsive(screen-phone) {
            margin-top: 170px;
        }
    }
    .sell-courses {
        background: #f6f8f8;
        padding: 32px 0;
        @include responsive(screen-phone) {
            padding: 32px 0 8px 0;
        }
        h2 {
            text-align: center;
            @include MyriadPro;
            margin-bottom: 32px;
        }
        .course-list-item {
            margin-bottom: 0;
            h2 {
                margin-bottom: 0;
            }
            @include responsive(screen-phone) {
                margin-bottom: 24px;
            }
            .description {
                background: white;
            }
        }
    }
    .well.contact {
        background: white;
    }
    .column-list li {
        transition: all .3s;
        padding: 0;
        @include responsive(screen-phone) {
            margin-bottom: 0;
        }
        a {
            display: block;
            padding: 12px;
            overflow: hidden;
            float: left;
            width: 100%;
            &:hover {
                background: white;
            }
        }
        &:hover {
            a, h5 {
                text-decoration: none;
                color: #337ab7;
            }
        }
    }
    .btn.secondary {
        text-align: center;
        display: block;
        margin: 24px auto 12px auto;
        font-size: 15px;
        border: 1px solid $start-color;
        color: $start-color;
        padding: 12px 32px;
        width: 288px;
        &:hover {
            color: white;
            background: $start-color;
        }
    }
    .btn.main {
        width: 214px;
        @include responsive(screen-phone) {
            width: 100%;
        }
    }
    .jumbotron.banner h1 {
        @include responsive(screen-phone) {
            height: 81px;
            padding: 0 32px;
        }
    }
}

.video-intro {
    padding: 46px 0;
    @include responsive(screen-phone) {
        padding: 24px 0;
    }
    .play-video {
        display: block;
        box-shadow: 2px 1px 12px 0px #bfbfbf;
        img {
            width: 100%;
            @include responsive(screen-dekstop) {
                  width: auto;
            }
        }
    }
    h3 {
        color: #393d40;
        font-size: 24px;
        @include MyriadPro;
        position: relative;
        padding-left: 64px;
        padding-top: 40px;
        margin-bottom: 32px;
        @include responsive(screen-phone) {
            font-size: 21px;
            padding-top: 0;
            padding-left: 50px;
            margin-bottom: 24px;
        }
        @include responsive(screen-tablet) {
           padding-top: 0;
           margin-bottom: 24px;
           font-size: 21px;
        }
        &:before {
            @include ab-center;
            right: auto;
            background: url(../img/layout/icons/svg/laptop.svg) no-repeat 0 0;
            background-size: 44px 44px;
            width: 48px;
            height: 40px;
            top: 38px;
            @include responsive(screen-phone) {
                background-size: 36px 36px;
                top: 8px;
            }
            @include responsive(screen-tablet) {
               top: 0;
            }
        }
    }
    ul {
        list-style: none;
        padding-left: 4px;
        @include responsive(screen-phone) {
            margin-bottom: 0;
        }
        li {
            background: url(../img/layout/icons/svg/check-2.svg) no-repeat 0 4px;
            background-size: 24px 24px;
            font-size: 19px;
            line-height: 32px;
            padding-left: 40px;
            margin-bottom: 15px;
            @include responsive(screen-phone) {
                margin-bottom: 4px;
            }
             @include responsive(screen-tablet) {
               margin-bottom: 4px;
               font-size: 16px;
            }
            b {
                @include MyriadProBold;
            }
        }
    }
}

.about-blocks {
    padding: 32px 0;
    .about-block {
        margin-bottom: 64px;
        overflow: hidden;
        @include responsive(screen-phone) {
            margin-bottom: 0;
        }
    }
    .row:last-child .about-block {
        margin-bottom: 0;
        img {
            @include responsive(screen-phone) {
                margin-bottom: 0;
            }
        }
    }
    .description {
        h4 {
            position: relative;
            font-size: 18px;
            line-height: 24px;
            padding-left: 72px;
            padding-top: 0;
            margin-bottom: 16px;
            @include responsive(screen-desktop) {
                font-size: 24px;
                line-height: 32px;
                padding-left: 112px;
                margin-bottom: 34px;
                padding-top: 6px;
            }
            &:before {
                left: 0;
                background: white;
                border-radius: 50%;
                width: 50px;
                top: 0;
                height: 50px;
                box-shadow: 2px 1px 12px 0px #eaeaea;
                position: absolute;
                content: "";
                @include responsive(screen-desktop) {
                    width: 80px;
                    height: 80px;
                }
            } 
        }
         p {
            color: #525c65;
            font-size: 14px;
            line-height: 30px;
            width: 95%;
            @include responsive(screen-phone) {
                margin-bottom: 4px;
                line-height: 27px;
            }
            @include responsive(screen-desktop) {
                font-size: 16px;
            }
        }
        a {
             @include MyriadProBold;
             font-size: 14px;
             @include responsive(screen-phone) {
                 margin-bottom: 32px;
                 display: block;
            }
             @include responsive(screen-desktop) {
                font-size: 15px;
            }
             span {
                font-size: 23px;
                margin-left: 5px;
                position: relative;
                top: 1px;
                display: inline-block;

             }
         }
        &.access {
            h4 {
                color: #3498db;
                &:after {
                    background: url(../img/layout/icons/svg/wifi.svg) no-repeat 0 4px;
                    @include ab-center;
                    right: auto;    
                    background-size: 32px 32px;
                    left: 9px;
                    top: 6px;          
                    font-size: 21px;
                    width: 54px;
                    @include responsive(screen-desktop) {
                        background-size: 48px 48px;
                        left: 16px;
                        top: 12px;
                    }
                }
            }
        }
        &.catalog {
            h4 {
                color: #e67e22;
                &:after {
                    background: url(../img/layout/icons/svg/quality-2.svg) no-repeat 0 4px;
                    @include ab-center;
                    right: auto;
                    background-size: 32px 32px;
                    top: 5px;
                    left: 10px;                 
                    font-size: 21px;
                    width: 58px;
                    @include responsive(screen-desktop) {
                        background-size: 46px 46px;
                        left: 17px;
                        top: 13px;
                    }
                }
            }
        }
         &.vision {
            h4 {
                color: #e74c3c;
                &:after {
                    background: url(../img/layout/icons/svg/school-3.svg) no-repeat 0 4px;
                    @include ab-center;
                    right: auto;
                    background-size: 28px 28px;
                    top: 7px;
                    left: 11px;
                    font-size: 21px;
                    width: 52px;
                    @include responsive(screen-desktop) {
                        background-size: 42px 42px;
                        left: 19px;
                        top: 15px;
                    }
                }
            }
        }
    }
    img {
        box-shadow: 3px 4px 6.79px 0.21px rgba(95, 95, 95, 0.3); 
        display: block;
        @include responsive(screen-phone) {
            width: 100%;
            margin-bottom: 24px;
        }
        @include responsive(screen-tablet) {
            width: 100%;
            margin-top: 30px;
        }
    }
}

.entrances {
    padding: 32px 0;
    background: #f6f8f8;
    .entrance {
        img {
            width: 100%;
            box-shadow: 3px 4px 6.79px 0.21px rgba(95, 95, 95, 0.3); 
        }
        h5 {
            text-transform: none;
            font-size: 21px;
            @include MyriadPro;
            margin: 24px 0 16px 0;
            @include responsive(screen-tablet) {
                line-height: 32px;
                height: 64px;
            }
        }
        p {
            font-size: 16px;
            line-height: 30px;
            height: 140px;
            @include responsive(screen-phone) {
                height: auto;
                font-size: 14px;
                line-height: 27px;
            }
            @include responsive(screen-tablet) {
                height: 190px;
            }
        }
        a {
            font-size: 15px; 
            @include responsive(screen-phone) {
                display: block;
                margin-bottom: 24px;
            }
        }
        &.large {
            a {
                float: right;
                @include responsive(screen-phone) {
                    float: none;
                }
                &.signup {
                    @include MyriadProBold;
                    font-size: 16px;
                    display: inline-block;
                    float: none;
                    margin-bottom: 0;
                    span {
                        font-size: 23px;
                        margin-left: 5px;
                        position: relative;
                        top: 1px;
                        display: inline-block;
                     }
                }
                &.main {
                    @include responsive(screen-phone) {
                        width: 100%;
                    }
                    &:last-child {
                        margin-right: 24px;
                        @include responsive(screen-phone) {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
