.c-button {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 3px;

    text: {
        decoration: none;
        align: center;
    }

    border: {
        width: 2px;
        style: solid;
    }

    transition: all 300ms ease;
    @include MyriadProBold;

    &:hover,
    &:visited,
    &:focus {
        color: #ffffff;
        text-decoration: none;
    }
}

.c-button--red {
    color: #ffffff;
    background-color: $brand-red;
    border-color: $brand-red;

    &:hover {
        background-color: rgba($brand-red, 0.8);
    }
}

.c-button--transparent {
    color: #ffffff;
    border-color: #ffffff;
    background-color: transparent;

    &:hover {
        background-color: rgba(255,255,255, 0.2);
    }
}

.c-button--blue {
    color: #ffffff;
    border-color: $brand-blue;
    background-color: $brand-blue;

    &:hover {
        background-color: rgba($brand-blue, 0.8);
    }
}

.c-button--white-trans {
    color: #ffffff;
    border-color: #ffffff;
    background-color: transparent;

    &:hover {
        color: $brand-red;
        background-color: #ffffff;
    }
}

.c-button--large {
    line-height: 60px;
    padding: 10px 20px;
    font-size: 20px;
}

.c-button--med {
    width: 275px;
    line-height: 60px;
    font-size: 24px;
}

.c-button--small {
    width: 190px;
    line-height: 44px;
    font-size: 16px;
}

.c-button--small-dynamic {
    line-height: 44px;
    font-size: 16px;
    padding: 0px 30px;
    margin: 5px 2px;
}

.c-button--xs {
    font-size: 12px;
    padding: 10px 40px;
}
 
// Button click animation
.c-button--animation {
    overflow: hidden;
}

.ink {
    position: absolute;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 100%;
    transform: scale(0);
}

.animate {
    -webkit-animation: ripple 0.65s linear;
    -moz-animation: ripple 0.65s linear;
    -ms-animation: ripple 0.65s linear;
    -o-animation: ripple 0.65s linear;
    animation: ripple 0.65s linear;
}

@-webkit-keyframes ripple {
    100% {
        opacity: 0;
        transform: scale(2.5);
    }
}

@-moz-keyframes ripple {
    100% {
        opacity: 0;
        transform: scale(2.5);
    }
}

@-o-keyframes ripple {
    100% {
        opacity: 0;
        transform: scale(2.5);
    }
}

@keyframes ripple {
    100% {
        opacity: 0;
        transform: scale(2.5);
    }
}




.noa-btn {
    transition: all .3s ease;

    &.btn-register {
        text-transform: uppercase;
        border: 1px solid $border-color;
        color: white;
        font-size: 12px;
        height: 27px;
        line-height: 25px;
        width: 112px;
        text-align: center;
        padding: 0;
        background: $highlight-color;
        text-decoration: none;
        margin-right: 30px;
        float: left;
        margin-top: 15px;
        margin-bottom: 12px;
        @include MyriadProBold;

        &:hover {
            color: $link-color;
            background: white;
        }
    }

    &.btn-login {
        text-transform: uppercase;
        font-size: 12px;
        /*border-left: 1px solid #71798d;*/
        border-radius: 0;
        height: 14px;
        padding: 0 1em;
        /*padding-left: 30px;*/
        margin: 4px 0 0 0;
        line-height: 100%;
        @include MyriadProBold;

        &:hover {
            color: black;
            text-decoration: underline;
        }

        &.btn-login__leftborder {
            border-left: 1px solid #71798d;
        }

        &.btn-login__nolink:hover {
            cursor: default;
            text-decoration: none;
        }
    }

    &.btn-default {
        background: none;
        color: $highlight-color;
        padding: 12px 56px;
        text-transform: uppercase;
        border: 1px solid $highlight-color;
        font-size: 16px;
        border-radius: 3px;
        white-space: normal;
        @include MyriadProBold;

        &:hover, &:active {
            border: 1px solid #7e819d;
            background: #9699bb;
            color: $white;
            box-shadow: none;
            text-decoration: none;
        }

        &.disabled {
            border: 1px solid;
            font-size: small;
            font-weight: normal;
            background: none;
        }

        &.main {
            border: 1px solid #e67e22;
            color: #e67e22;

            &:hover {
                color: white;
                background: #e67e22;
            }
        }

        &.small-button {
            width: 44px;
            height: 44px;
            border-radius: 50px;
            padding: 0;
            position: relative;

            .icon {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                text-align: center;
                line-height: 44px;
                color: $white;
            }
        }

        &.neighbours {
            padding: 10px 0;
            width: 49%;
        }

        &.transparent {
            border: none;
            color: $white;
            font-weight: 700;

            &:hover {
                background: rgb(0, 0, 0);
            }
        }
    }

    &:focus {
        outline: none;
    }

    &.btn-secondary {
        padding: 12px 42px;
        text-transform: uppercase;
        border: 1px solid $border-color;
        background: $highlight-color;
        color: $white;
        box-shadow: none;
        text-decoration: none;
        font-size: 14px;
        border-radius: 0;
        white-space: normal;
        @include MyriadProBold;
        margin-left: 16px;

        &:hover {
            background: transparent;
            color: $link-color;
        }
    }

    &.go {
        display: block;
        text-decoration: none;
        border: 1px solid #e67e22;
        background: white;
        @include MyriadProBold;
        text-transform: uppercase;
        color: #e67e22;
        font-size: 16px;
        width: 100%;
        height: 37px;
        line-height: 37px;
        padding: 0 26px;
        border-radius: 0;
        transition: all .3s;

        &:hover {
            background: #e67e22;
            color: white;
        }
    }
}
a.noa-btn {
	display: inline-block;
	vertical-align: middle;
}

.btn-wrap {
    text-align: left;
    &.center {
        position: relative;
        text-align: center;
        z-index: 200;
        height: 44px;
        a {
            .neighbours{
                position: absolute;
            }
            .transparent{
                right: 0;
            }
        }
    }
}