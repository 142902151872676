﻿.i-parallax-icon {
    position: absolute;
    z-index: -1;

    &.i-parallax-icon__layer-1 {
        top: 80px;
        left: -70px;
    }

    &.i-parallax-icon__layer-2 {
        top: 310px;
        left: -70px;
    }

    &.i-parallax-icon__layer-3 {
        top: -60px;
        left: 100px;
    }

    &.i-parallax-icon__layer-4 {
        top: -50px;
        right: 90px;
    }

    &.i-parallax-icon__layer-5 {
        top: -70px;
        right: 400px;
    }

    &.i-parallax-icon__layer-6 {
        top: 170px; 
        right: -80px; 
    }

    &.i-parallax-icon__layer-7 {
        bottom: 50px;
        right: -70px;
    }
}


.i-parallax-girl {
    position: relative;
    left: 0;
    top: 0;

    &.i-parallax-icon__layer-0 { 

        @include responsive-custom(0px, 1024px) {
            display: none;
        }
    }
}
