﻿input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: $white;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1px solid $gray-normal;
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: $cadet-blue;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1px solid $gray-normal;
}

.c-radio-button-holder {
  .c-radio-button {
    &:first-child {
      z-index: 3;
    }

    &:nth-of-type(2) {
      z-index: 2;
    }
  }

  @include responsive-custom(0, 700px) {
    .c-radio-button {
      display: block;
      text-align: left;

      &:not(:first-child) {
        margin-top: 25px;
      }
    }
  }
}

.c-radio-button {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 0 20px 0 30px;
  line-height: 20px;

  &.is-inactive {
    &:before {
      content: "";
      position: absolute;
      left: 0;
      height: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      cursor: not-allowed;
    }

    label {
      cursor: not-allowed;
      color: rgba($brand-blue, 0.2);
      user-select: none;

      &:before {
        border-color: rgba($brand-blue, 0.2);
      }

      &:hover {
        color: currentColor;
      }
    }
  }

  input[type="radio"] {
    display: none;

    &:checked + label {
      color: $brand-blue;

      &:before {
        border-color: $brand-blue;
      }

      &:after {
        transform: scale(1);
      }
    }
  }

  label {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 5px;
    color: rgba($brand-blue, 0.5);
    transition: all 300ms ease;

    &:hover {
      color: rgba($brand-blue, 0.8);

      &:before {
        border-color: rgba($brand-blue, 0.6);
      }
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      border-radius: 100%;
      transition: all 0.3s ease;
    }

    &:before {
      top: 0;
      left: -30px;
      background-color: #ffffff;
      border: 1px solid #dddddd;
      width: 20px;
      height: 20px;
    }

    &:after {
      background-color: $brand-blue;
      width: 6px;
      height: 6px;
      top: 7px;
      left: -23px;
      transform: scale(0);
    }
  }
}
