﻿@import "main";
@import "header";
@import "banner";
@import "buttons";
@import "tabs";
@import "logos";
@import "radio-buttons";
@import "check-box";
@import "form";
@import "search-box";
@import "footer";
@import "courses-slider";
@import "alb-widget";
@import "tooltip";
@import "video-player";
@import "error-pages";
@import "cards";
@import "modal";
@import "privacy";
@import "parallax";
@import "globalization";
