.nav-wrapper {
  .results-close {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 53px;
    background: transparent;
    z-index: 50;
  }

  .navbar {
    @include responsive(screen-desktop) {
      .container {
        position: relative;
      }

      .user-area {
        position: absolute;
        right: 0;
      }

      .dropdown-menu {
        left: auto;
        right: 0;
      }

      .search-results {
        display: none;
        left: auto;
        right: 0;
        top: 84px;
        border-top: 0;
        width: 234px;
        padding: 0;
      }

      .results-close {
        display: none;
      }
    }
  }
}

.noa-navbar {
  border: 0;
  margin: 0;
  background: white;
  position: fixed;
  top: 0;
  transition: all 0.3s;
  box-shadow: 0 3px 5px -4px rgba($black, 0.35);

  @include responsive(screen-desktop) {
    z-index: 1035;
  }

  .container {
    @include responsive(screen-phone) {
      width: 100%;
    }

    @include responsive(screen-tablet) {
      width: 100%;
    }
  }

  .navbar-collapse {
    background: white;

    @include responsive(screen-tablet) {
      width: 100%;
      border-bottom: 1px solid #ccc;
      padding-bottom: 4px;
    }

    @include responsive(screen-phone) {
      background: white;
      border-bottom: 1px solid #ccc;
      padding-bottom: 4px;
    }

    &[aria-expanded="true"] {
      .navbar-nav {
        padding-bottom: 15px;
      }

      .pull-right {
        float: none;
      }
    }
  }

  .navbar-nav {
    font-size: 14px;
    font-family: sans-serif;
    text-transform: uppercase;

    > li {
      display: inline-block;
      text-align: center;
      width: 96%;
      text-align: left;

      @include responsive(screen-desktop) {
        margin-left: auto;
        width: auto;
      }

      > a {
        color: #212121;
        padding: 10px 15px;
        border-bottom: 4px solid transparent;
        font-size: 13px;
        display: inline-block;
        text-align: center;
        width: 100%;
        text-align: left;
        @include MyriadPro;

        &:hover,
        &:focus {
          background: none;
          font-weight: bold;
        }

        &:hover {
          border-color: #7d97ad;
        }

        &:after {
          display: block;
          content: attr(title);
          font-weight: bold;
          height: 1px;
          color: transparent;
          overflow: hidden;
          visibility: hidden;
          margin-bottom: -1px;
        }
      }

      &.active a {
        background: none;
        border-color: #2c5179;
      }

      &.disabled {
        padding: 15px 10px;
        line-height: 20px;
        color: $list-group-disabled-color;
        cursor: $cursor-disabled;
      }
    }
  }

  .navbar-brand-container {
    position: relative;

    .navbar-brand {
      overflow: hidden;

      @include responsive(screen-desktop) {
        overflow: visible;
      }
    }
  }

  .navbar-brand.hidden-md:before,
  .navbar-brand.hidden-md:after {
    @include responsive(screen-tablet) {
      display: block;
    }
  }

  .navbar-toggle {
    border: 0;
    border-radius: 0;
    margin-top: 0;
    margin-right: 0;
    float: none;
    width: 62px;
    margin-bottom: 0;
    height: 53px;

    .nav-text {
      font-size: 12px;
      display: block;
      margin-top: -5px;
      color: #393d40;
    }

    .icon-bar {
      width: 24px;
      margin: 4px auto;
    }

    @include responsive(screen-desktop) {
      display: none;
    }
  }

  .icon-bar {
    background-color: #393d40;
  }

  &.extra-navbar {
    z-index: 1031;
    top: 0;
    position: fixed;
    padding-top: 0;

    @include responsive(screen-tablet) {
      .container {
        width: 100%;
      }
    }

    @include responsive(screen-desktop) {
      margin-right: auto;
    }

    #navbar {
      height: 200px;

      @include responsive(screen-desktop) {
        margin-top: 0;
      }

      &.collapse {
        height: auto;
        margin-top: 1px;
        margin-right: -15px;
        margin-left: -15px;
        border-top: 1px solid $highlight-color;

        &.open {
          display: block;
        }
      }
    }
  }

  &.navbar-fixed-top .user-area {
    margin-bottom: -20px;

    .user {
      margin-left: 10px;
    }

    @include responsive(screen-phone) {
      display: none;
    }
  }
}

.subscribe-bar {
  display: none;
  height: 40px;
  width: 100%;
  background: #00a652;
  position: fixed;
  overflow: hidden;
  z-index: 10;
  transition: all 0.3s;
  top: 96px;

  @include responsive(screen-phone) {
    height: auto;
  }

  .close {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 18px;
    width: 16px;
    height: 16px;
    background: url(../img/layout/icons/lightbox-close.png);
    z-index: 1;

    @include responsive(screen-tablet) {
      top: 12px;
    }

    @include responsive(screen-desktop) {
      top: 12px;
    }
  }

  .col-xs-12 {
    text-align: center;

    .content {
      display: inline-block;

      @include responsive(screen-phone) {
        text-align: center;
      }

      p {
        margin: 0;
        color: white;
        margin: 15px 0;
        padding: 0 20px;

        @include responsive(screen-from-tablet) {
          float: left;
          margin-top: 8px;
          padding: 0;
        }
      }

      a {
        @include MyriadProBold;
        border: 1px solid white;
        text-transform: uppercase;
        display: block;
        float: left;
        padding: 1px 14px;
        color: white;
        margin-top: 5px;
        margin-left: 15px;
        font-size: 12px;
        transition: all 0.3s;
        text-decoration: none;
        display: none;

        &:hover {
          background: white;
          color: #00a652;
        }

        @include responsive(screen-phone) {
          float: none;
          margin: 3px auto 7px auto;
          display: inline-block;
          display: none;
        }
      }
    }
  }
}

.payment-status + .subscribe-bar {
  display: none !important;
}

.container > .navbar-header,
.container > .navbar-collapse,
.container-fluid > .navbar-header,
.container-fluid > .navbar-collapse {
  margin-right: auto;
  margin-left: auto;
}
