﻿.payment-status{
    display: none !important;
    background: $idle;
    margin-right: 0;
    .message{
        padding: 5px 0;
        color: $white;        
        text-align: center;
        line-height: 20px; 
        @include responsive(screen-desktop){
            margin: 0 auto;
            width: 620px;
        }       
        p {
            position: relative; 
            margin-bottom: 0;
        }
    }
}