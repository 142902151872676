﻿.c-alb {
    position: relative;
    color: #ffffff;
    margin-right: 400px;

    @include responsive-custom(800px, 1200px) {
        margin-right: 35px;
    }

    @include responsive-custom(0, 800px) {
        width: 100%;
        float: none;
        margin-left: 0;
        margin-top: 50px;

        br {
            display: none;
        }
    }



    .c-alb__logo {
        position: absolute;
        right: -35px;
        top: -30px;

        @include responsive-custom(0, 800px) {
            width: 80px;
            top: -50px;
            right: -15px;
        }
    }

    div {
        padding: 20px 30px;
    }

    .c-alb__body {
        background-color: currentColor;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .c-alb__footer {
        background-color: #e9eaf4;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}