﻿.training {
    h5 {
        @include MyriadProBold;
        font-size: 24px;
        text-transform: none;
        color: #393d40;
        line-height: 100%;
    }
    .scrollable-content {
        .aspect-ratio {
            margin-top: 0;
        }
        h4 {
            margin-bottom: 32px;
            text-align: left;
            @include responsive(screen-phone) {
                margin: 24px 0;
            }
        }
    }
    p {
        margin-bottom: 0;
        @include responsive(screen-from-tablet) {
            margin-bottom: 30px;
        }
    }
    .video-placeholder {
        display: none;
        width: 100%;
        position: relative;
        overflow: hidden;
        margin: 0 auto;
        @include responsive(screen-phone) {
            margin-bottom: 24px;
        }
        @include responsive(screen-tablet) {
            height: 320px;
        }
        @include responsive(screen-desktop) {
            margin-bottom: 10px;
            height: 372px;
            width: 660px;
        }
        img {
            width: 100%;
        }
        .overlay {
            background: rgba(51,122,183,.8);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        p {
            font-size: 18px;
            padding: 0;
            color: white;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            text-align: center;
            height: 35px;
        }
        &.webinar-podcast {
            height: 357px;
            @include responsive(screen-phone) {
                height: auto;
            }
        }
    }
    .cta-block {
         h5 {
            @include responsive(screen-phone) {
                margin-top: 24px;
            }
        }
    }
    .detail-intro .segment {
        margin-top: 32px;
        display: block;
        @include responsive(screen-phone) {
            margin-top: 12px;
        }
    }
     .summary {
        margin-top: 32px;
        display: block;
        @include responsive(screen-phone) {
            margin-top: 0;
        }
     }
}

.detail-intro {
    background: #f6f8f8;
    color: #7d97ad;
    font-size: 16px;
    padding: 46px 0;
    @include responsive(screen-phone) {
        padding: 32px 0;
    }
    .segment {
        @include MyriadProBold;
        text-transform: uppercase;
    }
    h2 {
        @include MyriadPro;
        color: #393d40;
        font-size: 30px;
        margin: 12px 0 38px 0;
        float: left;
         @include responsive(screen-phone) {
            margin: 12px 0 24px 0;
        }
    }
    p {
        font-size: 16px;
        color: #393d40;
        line-height: 34px;
        padding-right: 30px;
        margin-top: -10px;
        @include responsive(screen-phone) {
            font-size: 14px;
            padding-right: 0;
            line-height: 28px;
            margin-bottom: 24px;
        }
    }
    .title {
        clear: both;
        overflow: hidden;
    }
    .category {
        background: #2C5179;
        display: block;
        @include MyriadProBold;
        font-size: 13px;
        color: white;
        height: 32px;
        line-height: 32px;
        padding: 0 8px;
        text-transform: uppercase;
        margin: 14px 0 0 16px;
        float: left;
        padding-right: 40px;
        position: relative;
        @include responsive(screen-phone) {
            clear: both;
            margin: 0 0 24px 0;
            line-height: 33px;
            width: auto;
        }
        &:before {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            height: 32px;
            width: 32px;
            background: #7d97ad;
        }
         &:after {
            content: "";
            position: absolute;
            background: url(../img/layout/icons/svg/trajectory.svg) no-repeat 0 3px;
            background-size: 20px;
            height: 32px;
            width: 32px;
            right: -5px;
            top: 2px;  
        }
    }
    .summary-title {
        color: #7d97ad;
        display: block;
        @include MyriadProBold;
        text-transform: uppercase;
        font-size: 14px;
        margin: 5px 0 20px 0;
        line-height: 100%;
        @include responsive(screen-phone) {
            margin-top: 46px;
        }
    }
    h3, .duration {
        color: #393d40;
        display: block;
        @include MyriadPro;
        font-size: 18px;
        margin-bottom: 32px;
    }
    .educator {
        margin-bottom: 32px;
        max-width: 160px;
        @include responsive(screen-phone) {
            margin-bottom: 0;
        }
    }
    .cta-block {
        text-align: left;
        padding: 0;
    }
}

.course-contents {
    background: white;
    padding: 42px 0;
    @include responsive(screen-phone) {
        padding: 24px 0;   
    }
    h4 {
        text-align: center;
        @include MyriadProBold;
        font-size: 24px;
        line-height: 100%;
        margin-bottom: 46px;
        @include responsive(screen-phone) {
            margin-bottom: 24px;
            font-size: 21px;
        }
    }
    .journey-contents {
        position: relative;
        &:after {
            content: "";
            width: 1px;
            background: #7d97ad;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 50px;
            height: 88%;
        }
        &:before {
            content: "";
            width: 21px;
            height: 21px;
            background: $brand-blue;
            border: 2px solid #7d97ad;
            border-radius: 50%;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 50px;
            z-index: 2;
        }
        .col-sm-6:nth-child(even) {
            .journey-part:before {
                content: "";
                position: absolute;
                top: 60px;
                height: 1px;
                margin: auto;
                right: -15px;
                width: 15px;
                background: #7d97ad;
                @include responsive(screen-phone) {
                    display: none;
                }
            }
        }
        .col-sm-6:nth-child(odd) {            
            .journey-part:before {
                content: "";
                position: absolute;
                bottom: 60px;
                height: 1px;
                margin: auto;
                left: -16px;
                width: 16px;
                background: #7d97ad;
                @include responsive(screen-phone) {
                    display: none;
                }
            }
        }
        h4 {
            margin-bottom: 64px;
        }
    }
    .journey-part {
        background: white;
        border: 1px solid #7d97ad;
        padding: 24px;
        min-height: 262px;
        margin-bottom: 30px;
        position: relative;
        z-index: 2;
        @include responsive(screen-phone) {
            padding: 16px;
            min-height: 0;
        }
         @include responsive(screen-tablet) {
            height: 252px;
        }
         &.finished {
             background:rgba(195, 255, 184, 0.1);
             border: 1px solid #6ac259;
         }
         a {
             color: #393d40;
             text-decoration: none;
         }
        h5 {
            margin-bottom: 16px;
            font-size: 24px;
            text-transform: none;
            @include responsive(screen-phone) {
                font-size: 18px;
                margin-top: 24px;
            }
        }
        .read-more {
            color: white;
        }
    }
    .journey-finish {
        display: block;
        position: relative;
        background: $brand-blue;
        border: 2px solid #7d97ad;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        margin: 0 auto;
        z-index: 2;
        &:after {
            background: url(../img/layout/icons/svg/racing-flag.svg) no-repeat;
            background-size: 21px 21px;
            content: "";
            position: absolute;
            width: 20px;
            height: 21px;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
        @include responsive(screen-phone) {
            margin-bottom: 32px;
        }
    }
    .category {
        @include MyriadProBold;
        display: block;
        font-size: 16px;
        color: #7d97ad;
        text-transform: uppercase;
        line-height: 100%;
        margin-bottom: 16px;
        padding-left: 36px;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: -7px;
        }
        &.course:before {
            background: url(../img/layout/icons/svg/school-2.svg) no-repeat 0 3px;
            width: 22px;
            height: 25px;
        }
        &.webinar:before {
            background: url(../img/layout/icons/svg/video-lecture-2.svg) no-repeat 0 3px;
            background-size: 24px;
            width: 24px;
            height: 25px;
        }
        &.podcast:before {
            background: url(../img/layout/icons/svg/headphones-2.svg) no-repeat 0 3px;
            background-size: 22px;
            width: 24px;
            height: 25px;
        }
    }
    .finished-label {
        color: white;
        background: #6ac259;
        position: absolute;
        font-size: 13px;
        top: 12px;
        right: 12px;
        padding: 4px 6px;
        height: 32px;
        margin-right: 32px;
        line-height: 26px;
        text-transform: uppercase;
        @include MyriadProBold;
        &:before {
            content: "";
            height: 32px;
            width: 32px;
            position: absolute;
            right: -39px;
            top: 0;
            background: url(../img/layout/icons/svg/check.svg) no-repeat 0 3px;
            background-size: 18px;
            top: 4px;
            z-index: 2;
        }
        &:after {
            background: #5c9451;
            content: "";
            height: 32px;
            width: 32px;
            position: absolute;
            right: -32px;
            top: 0;
            z-index: 1;
        }
    }
    p {
        font-size: 16px;
        color: #393d40;
        line-height: 30px;
        @include responsive(screen-phone) {
            font-size: 15px;
            line-height: 24px;
        }
        @include responsive(screen-tablet) {
            font-size: 15px;
        }
    }
    .read-more {
        display:block;
        text-decoration: none;
        cursor: pointer;
        background: #7d97ad;
        color: white;
        @include MyriadProBold;
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        padding: 0 12px;
        width: 90px;
        margin-left: 30px;
        transition: all .3s;
        position: absolute;
        bottom: 24px;
        @include responsive(screen-phone) {
            position: relative;
            bottom: auto;
        }
        &:hover {
            background: #657285; 
        }
        &:before {
            content: "";
            background: #e5e5e5;   
            height: 30px;
            width: 30px;
            position: absolute;
            top: 0;
            left: -30px;
            z-index: 1;
        }
        &:after {
            content: "";
            background: url(../img/layout/icons/svg/eyeglasses.svg) no-repeat 0 3px;
            height: 24px;
            width: 24px;
            position: absolute;
            top: 0;
            left: -27px;
            z-index: 2;
        }
    }
    .cta-block {
        h5 {
            margin: 32px 0;
        }
    }
}

.cta-block {
    padding: 32px 0 32px 0;
    text-align: center;
    @include responsive(screen-phone) {
        padding: 0 0 24px 0;
    }
    h6 {
        @include MyriadProBold;
        font-size: 24px;
        text-transform: none;
        color: #393d40;
        line-height: 100%;
        margin-bottom: 36px;
        text-align: center;
        @include responsive(screen-phone) {
            font-size: 21px;
            line-height: 30px;
        }
    }
    a {
        text-align: center;
        text-decoration: none;
        color: #e67e22;
        text-transform: uppercase;
        @include MyriadProBold;
        font-size: 16px;
        padding: 14px 16px;
        border: 1px solid #e67e22;
        border-radius: 3px;
        line-height: 100%;
        transition: all .8s;
        &:hover {
            background: #e67e22;
            color: white;
        }
        &:nth-child(even), &:nth-child(3) {
            margin-left: 20px;
        }
    }
    .or {
        @include MyriadProBold;
        font-size: 21px;
        margin: 12px 0;
        display: block;
        text-align:center;
    }
}