﻿@mixin main-gradient() {
    background: #9699bb; /* Old browsers */
    background: -moz-linear-gradient(top, #9699bb 0%, #757896 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #9699bb 0%,#757896 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #9699bb 0%,#757896 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9699bb', endColorstr='#757896',GradientType=0 ); /* IE6-9 */
}

@mixin radial-gradient($centerColor, $outerColor) {
    background: $centerColor; /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover, $centerColor 0%, $outerColor 100%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover, $centerColor 0%, $outerColor 100%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, $centerColor 0%, $outerColor 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$centerColor', endColorstr='$outerColor',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
