@mixin height-limiter($height, $row, $line-height: 1.1) {
  height: calc(#{$height} * #{$row} * #{$line-height});
  overflow: hidden;
  word-break: break-word;
}
@mixin grayscale-mode($percent){
	-webkit-filter: grayscale($percent);
	-moz-filter: grayscale($percent);
	-ms-filter: grayscale($percent);
	-o-filter: grayscale($percent);
	filter: grayscale($percent);
}
%show {
	font-size: 14px;
	padding-left: 40px;
	position: relative;
	%icon {
		position: absolute;
	    left: 10px;
	    top: 50%;
	    margin-top: -7px;
	}
}
@mixin gutter($gutter){
    margin-bottom: $gutter;
}
hr{
        border-top: solid #595960;
        border-width: 1px 0;
}