﻿/*Redesign 2020 inputs*/

.input__wrapper {
  position: relative;
  margin-bottom: 20px;
}

.input__field {
  font-size: 14px;
  padding: 4px 4px;
  display: block;
  width: 100%;
  height: 30px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid $gray;

  &:focus {
    border-color: $cadet-blue;
    box-shadow: 0px 5px 5px -4px $cadet-blue;
  }
}
.input__field--message {
  height: 150px;
}

.input__label {
  color: #999;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 5px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.input__field:focus {
  outline: none;
  border-bottom: 2px solid $cadet-blue;
}

.input__field:focus ~ label,
.input__field:not(:placeholder-shown) ~ label,
.input__field[data-val="true"] ~ label {
  top: -18px;
  font-size: $font-size-small;
  color: $gray;
}
/* active state */
.input__field:focus ~ .input__highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

.input__field[aria-invalid="false"] {
  border-color: $green-color;
}

.input__field[aria-invalid="true"] {
  border-color: $error-color;
}

.error-msg {
  color: $error-color;
  font-size: $font-size-small;
}

/* animation */
@-webkit-keyframes inputHighlighter {
  from {
    background: $cadet-blue;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: $cadet-blue;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: $cadet-blue;
  }
  to {
    width: 0;
    background: transparent;
  }
}

/* end of new inputs*/

.form {
  position: relative;
  width: 100%;
  max-width: 840px;
  margin: -100px auto 100px;
  background-color: #ffffff;
  padding: 40px;
  z-index: 2;
  box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.4);
  border-radius: 2px;

  @media screen and (max-width: 768px) {
    padding: 30px 15px;
  }

  .g-recaptcha {
    > div {
      width: 100% !important;
    }
  }

  h1 {
    display: block;
    font-size: 32px;
    @include MyriadPro;
    color: $brand-blue;
    margin-bottom: 40px;
    text-transform: none;
    line-height: 1.3em;

    @media screen and (max-width: 768px) {
      font-size: 26px;
    }
  }

  .form-group {
    padding-top: 35px;
    margin-bottom: 0;
    @include clearfix;

    .row {
      @media screen and (max-width: 768px) {
        .col-sm-6,
        .col-xs-6 {
          &:not(:first-child) {
            margin-top: 35px;
          }
        }
      }
    }
  }

  .form-group__buttons {
    position: relative;
    z-index: 2;
    margin-top: 20px;

    .c-button {
      &.is-active {
        background-color: $brand-red;
        border-color: transparent;
      }

      &:not(:nth-of-type(1)) {
        margin-left: 15px;

        @media screen and (max-width: 420px) {
          margin-left: 0;
          margin-top: 15px;
        }
      }
    }

    h2 {
      font-size: 15px;
      color: rgba($brand-blue, 0.7);
      margin: 0 0 25px 0;
    }
  }

  .c-birth-date-holder {
    position: relative;
    display: none;
    margin-top: 35px;
    opacity: 0;
    @include transform(translateY(10px));
    transition: all 300ms cubic-bezier(0.3, 1.1, 0.6, 1.2);

    &.is-active {
      opacity: 1;
      @include transform(translateY(0px));
      display: block;

      &.is-required {
        label.form-label {
          &:after {
            content: "*";
            display: inline-block;
          }
        }
      }
    }

    .form-label {
      z-index: 1;
    }

    .is-required {
      display: none;
    }
  }

  .c-birth-date {
    font-size: 14px;
    margin: 0;
    color: rgba($brand-blue, 0.7);
    margin-bottom: 10px;

    @media screen and (max-width: 768px) {
      margin-bottom: 15px;
    }

    span {
      position: relative;
      font-size: 8px;
      top: -3px;
    }
  }

  .input-wrapper {
    position: relative;
    @include clearfix;
  }

  .form-label {
    position: absolute;
    top: 0;
    left: 0;
    color: rgba($brand-blue, 0.5);
    padding: 6px 0;
    @include transform(translateY(0px));
    transition: all 0.2s ease;
    z-index: -1;

    &.tooltip-label {
      z-index: 2;
    }
  }

  .form-control {
    position: relative;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid rgba($brand-blue, 0.5);
    box-shadow: none;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    /*&:focus, &:active, */
    &.has-value,
    &.tooltip-val {
      outline: none;
      box-shadow: none;
      border-color: $brand-blue;
      box-shadow: 0px 5px 5px -4px rgba($brand-blue, 0.3);

      + label {
        font-size: 12px;
        color: $brand-blue;
        @include transform(translateY(-20px));

        .c-tooltip__icon {
          color: #ffffff;
        }
      }

      + .c-tooltip__icon {
        color: #ffffff;
      }
    }
  }

  .c-notes {
    font-size: 13px;
    color: rgba($brand-blue, 0.5);

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  input[type="checkbox"] {
    &:checked + label {
      &:after {
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  .c-checkbox {
    position: relative;
    font-size: 14px;
    color: rgba($brand-blue, 0.7);
    padding-left: 30px;
    line-height: 1.3em;
    cursor: pointer;

    &:before,
    &:after {
      content: "";
      position: absolute;
      transition: all 300ms ease;
    }

    &:before {
      left: 0;
      top: 2px;
      width: 15px;
      height: 15px;
      border-radius: 2px;
      border: 1px solid rgba($brand-blue, 0.5);
    }

    &:after {
      left: 2px;
      top: 4px;
      width: 11px;
      height: 11px;
      opacity: 0;
      transform: scale(0);
      background-color: rgba($brand-blue, 0.5);
    }
  }

  button {
    border: none;
    margin-top: 60px;

    @media screen and (max-width: 420px) {
      width: 100%;
    }
  }

  select {
    color: rgba($brand-blue, 0.5);

    optgroup {
      @include MyriadProBold;
      color: rgba($brand-blue, 0.5);
    }

    &.form-control {
      &.has-value {
        color: rgba($brand-blue, 0.65);
        box-shadow: none;
      }
    }
  }

  select::-ms-expand {
    display: none; /* remove default arrow on ie10 and ie11 */
  }
}
