﻿video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}
.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.aspect-ratio {
    position: relative;
    margin-bottom: 50px;
    margin-top: 40px;
    padding-bottom: 50%;
    width: 100%;
    height: 0;
    @include responsive(screen-desktop) {
        height: auto;
        padding-bottom: 0;
        margin-top: 0;
        margin: 0 auto;
        width: 660px;
    }
    .vidPlayer {
        position: absolute;
        width: 100%;
        height: 113%;
        top: 0;
        left: 0;
        border: none;
        @include responsive(screen-desktop) {
            position: relative;
            height: 371px;
        }
    }
}
.live {
    .mobile-title {
        @include responsive(screen-phone) {
            margin-top: 20px;
        }
    }
    .video-wrapper {
        margin: 0 0 30px 0;

        @include responsive(screen-from-tablet) {
            margin: 15px 0 30px 0;
        }
    }
    .placeholder-text {
        font-size: 18px;
        margin: 30px 0;
        display: block;
    }
    .button-wrapper {
        @include responsive(screen-phone) {
            margin: 0 0 20px 0;
        }
        margin-top: 20px;
        .btn:first-child {
            margin-left: 0;
            margin-right: 10px;
        }
    }
    .btn-default {
        padding: 12px 42px;
        height: 45px;
        border-radius: 0;
    }
    .btn-secondary {
        margin-left: 0;
    }
    .not-logged {
        p {
            min-height: 500px;
        }
        a {
            color: $brand-primary;
        }
    }
}

.video-header {
    position: relative;
    height: 100%;
    width: 100%;
    display: block;
    -webkit-animation: fadeInFromNone 1s ease-out;
    -moz-animation: fadeInFromNone 1s ease-out;
    -o-animation: fadeInFromNone 1s ease-out;
    animation: fadeInFromNone 1s ease-out;
    @include responsive(screen-desktop) {
        top: 0;
        transition: top, .3s;
    }
    &.sticky {
        @include responsive(screen-desktop) {
            top: -42px;
        }
    }
    @-webkit-keyframes fadeInFromNone {
        0% {
            display: none;
            opacity: 0;
        }

       80% {
            display: block;
            opacity: 0;
        }

        100% {
            display: block;
            opacity: 1;
        }
    }

    @-moz-keyframes fadeInFromNone {
        0% {
            display: none;
            opacity: 0;
        }

        80% {
            display: block;
            opacity: 0;
        }

        100% {
            display: block;
            opacity: 1;
        }
    }

    @-o-keyframes fadeInFromNone {
        0% {
            display: none;
            opacity: 0;
        }

        80% {
            display: block;
            opacity: 0;
        }

        100% {
            display: block;
            opacity: 1;
        }
    }

    @keyframes fadeInFromNone {
        0% {
            display: none;
            opacity: 0;
        }

       80% {
            display: block;
            opacity: 0;
        }

        100% {
            display: block;
            opacity: 1;
        }
    }
    @include responsive(screen-tablet) {
        margin-bottom: -40px;
    }
    @include responsive(screen-desktop) {
        margin-bottom: -125px;
    }
    @media (min-width: 1020px) and (max-width: 1400px) { 
        margin-bottom: -40px;
    }
    .bg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, .1);
        z-index: 1;
    }
    .overlay {
        @include ab-center;
        @include responsive(screen-tablet) {
            height: 64px;
        }
        @include responsive(screen-desktop) {
            top: -125px;
        }
        @media (min-width: 1020px) and (max-width: 1400px) { 
            top: -40px;
        }
        z-index: 1;
        text-align: center;
        color: white;
        height: 100%;
        height: 92px;
    }
    h1, h2 {
        color: white;
        text-transform: none;
        @include MyriadProBold;
        text-shadow: 3px 3px #2b2b2b;
        @include responsive(screen-tablet) {
            font-size: 28px;
        }
    }
    h2 {
        margin-top: 20px;
        font-size: 22px;
        text-shadow: 2px 2px #2b2b2b;
        @include responsive(screen-tablet) {
            margin-top: 10px;
            font-size: 20px;
        }
    }
}

.c-videoAnnouncementText {
    font-size: 15px;
    margin: 24px 0;
}