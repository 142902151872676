﻿.c-card-container {
    background-color: #f6f8f8;
    padding: 100px 0;

    .container {
        @include responsive-custom(768px, 1024px) {
            width: 100%;
        }
    }
}

.c-card {
    margin-top: 30px;
    transition: all 300ms ease;

    &.is-disabled {
        pointer-events: none;
        opacity: 0.4;
        cursor:not-allowed;
        /*display: none;*/
    }
}

.c-card__head {
    height: 50px;
    background-color: #118090;
    padding: 0 20px; 

    h1 {
        line-height: 50px;
        font-size: 16px;
        color: #ffffff;
    }
}

.c-card__body {
    position: relative;
    border: 1px solid rgba(0,0,0,0.07);
    border-top: none;
    box-shadow: 0px 4px 4px -3px rgba(0,0,0,0.2);
    background-color: #ffffff;
    padding: 20px 20px 50px;
    min-height: 180px;

    p {
        margin: 0;
    }


    .c-card__buttons {
        position: absolute;
        left: 20px;
        bottom: 20px;

        label {
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-bottom: 0;
            font-weight: 400;
            color: rgba($brand-blue, 0.6);
            transition: all 300ms ease;
            z-index: 1;
        }

        input {
            display: none;
        }
    }

    .c-card__radio {
        position: absolute;
        left: 200px;
        bottom: 21px;
        visibility: hidden;

        &.is-on {
            visibility: visible;

            .c-radio-button {
                opacity: 1;
                visibility: visible;
                transition-delay: 200ms;

                &:nth-of-type(2) {
                    transition-delay: 300ms;
                }
            }
        }

        @include responsive-custom(768px, 860px) {
            left: 15px;
            bottom: 60px
        }

        @include responsive-custom(0, 440px) {  
            left: 15px;
            bottom: 60px; 
        }

        .c-radio-button {
            padding: 0 0px 0 30px;
            opacity: 0;
            visibility: hidden;
            transition: all 350ms ease-in-out;

            &:not(:first-child) {
                margin-left: 10px;
            }

            label {
                margin-bottom: 0;

                &:before {
                    background-color: transparent;
                    left: -25px;
                }

                &:after {
                    left: -18px;
                }
            }
        }
    }
} 

.c-card__switch + .c-card__switch-label {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 15px;
    margin: 0 10px;
    background-color: rgba(#f44336, 0.6);
    border-radius: 25px;
    transition: all 300ms ease;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background-color: #f44336;
        box-shadow: 0 3px 3px -1px rgba(0,0,0,0.5);
        @include transform(translateY(-50%));
        transition: all 250ms ease;
    }

}


.c-card__switch:checked ~ .c-card__switch-label {
    background-color: rgba(#0bb61e, 0.6);

    &:before {
        left: calc(100% - 23px);
        background-color: #0bb61e;
    } 
}

.c-card__switch:checked ~ label {
    color: $brand-blue; 
}
 


/*.c-card__switch-reminder:checked ~ .c-card__radio {
    visibility: visible;

    .c-radio-button {
        opacity: 1;
        visibility: visible;
        transition-delay: 200ms;

        &:nth-of-type(2) {
            transition-delay: 300ms;
        }
    }
}*/