﻿.is-logged-user {
    background: #fff4db;
}

.delete-not-allowed {
    padding: 1em;
    color: lighten(black, 33%);
    font-weight: bold;
    background: #ffdd88;
}

.btn-default, .btn-default[disabled] {
    background: $highlight-color;
    border: 1px solid darken($highlight-color, 5%);
    transition: all 0.3s ease-in-out;

    &:hover {
        background: lighten($highlight-color,10%);
        border: 1px solid darken($highlight-color, 15%);
    }

    &[disabled] {
        background: $highlight-color;
        border: 1px solid darken($highlight-color, 5%);
    }
}

.c-table {
    width: 100%;

    .c-table__header {
        & > tr {
            border-bottom: 1px solid $highlight-color;
        }
    }

    .c-table__row {
        /*padding: 0.5em;*/
    }

    .c-table__cell {
        padding: 0.5em;

        &.c-table__cell--nowrap {
            width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.c-outline {
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 4px;
    padding: 0.2em 0.7em;
    margin-left: 0.5em;
}

.c-grid {
    width: 100%;
    height: 80vh;
}
