﻿/* These mixins encapsulate all used media queries */

/*These will apply for a specific platform*/
@mixin responsive($media){
    @if $media == screen-phone {
            @media only screen and (max-width: ($screen-sm - 1px)){ @content; }
        }
        @else if $media == screen-tablet {
            @media only screen and (min-width: $screen-sm - 1px) and (max-width: $screen-md) { @content; }
        }
        @else if $media == screen-from-tablet {
            @media only screen and (min-width: $screen-sm - 1px) and (max-width: $screen-lg){ @content; }
        }
        @else if $media == screen-desktop {
            @media only screen and (min-width: $screen-md) { @content; }
        }  
}

@mixin responsive-custom($min, $max){
    @media only screen and (min-width: $min) and (max-width: $max) { @content; }
}