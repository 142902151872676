body {
  font-size: $font-size-base;
  color: $text-color-primary;
  @include MyriadPro;
  -webkit-font-smoothing: antialiased;
}
h1 {
  font: 36px/1.1 sans-serif;
  margin: 0;
}

h2,
h3 {
  font-size: 24px;
  @include MyriadProBold;
  margin: 0 0 45px 0;
  color: black;
  @include responsive(screen-phone) {
    margin: 0 0 24px 0;
  }
  &.about {
    span {
      background: url(../img/layout/icons/about-academy.png) no-repeat 0 50%;
      padding-left: 65px;
      display: inline-block;
      line-height: 45px;
    }
  }
}
h4 {
  font-size: 20px;
  line-height: 1.1;
  margin: 0 0 5px 0;
}
h5 {
  @include MyriadProBold;
  font-size: 15px;
  line-height: 1.1;
  margin: 0;
  text-transform: uppercase;
  &.text-sentence {
    text-transform: inherit;
  }
}
h6 {
  font-size: 12px;
  line-height: 1.1;
  margin: 0 0 25px 0;
  text-transform: uppercase;
}
* {
  outline: none;
}

ul,
li {
  list-style-type: none;
}

.center {
  text-align: center;
}
.hidden {
  display: none;
}
.row-centered {
  text-align: center;
}
.col-centered {
  display: inline-block;
  float: none;
  text-align: left;
  margin-right: -4px;
  &.hidden {
    display: none;
  }
}
.icon-wrap {
  display: block;
  width: 23px;
  height: 23px;
  line-height: 23px;
  text-align: center;
  border: 1px solid $black;
  border-radius: 50%;
}
.noa-content-wrapper {
  &.my-courses {
    border-top: solid 1px $black;
    @include responsive(screen-from-tablet) {
      border-top: none;
    }
  }
}

@media (min-width: 1024px) {
  .wrapper--height {
    height: calc(100vh - 370px);
  }
}

.noa-navbar:not(.navbar-fixed-top) + .site-container {
  padding-top: 0;
}
.site-container {
  min-height: 100%;
}
.site-container:after {
  content: "";
  display: block;
}
.footer-wrap,
.site-container:after {
  //height: 174px;
}
.validation-summary-errors ul {
  list-style: none;
  padding: 0;
}
.bottomSegment,
.bottomTopCourse {
  display: none;
}
.o-error {
  padding: 120px 0;
}

.tabs {
  position: relative;
  width: 100%;
  &:after {
    position: absolute;
    height: 1px;
    background: #e6e6e6;
    bottom: -1px;
    content: "";
    width: 100%;
    left: 0;
    z-index: 1;
  }
  .tab {
    cursor: pointer;
    float: left;
    height: 40px;
    padding: 0 24px;
    line-height: 40px;
    @include MyriadPro;
    font-size: 18px;
    @include responsive(screen-phone) {
      padding: 0 12px;
    }
    &.active {
      border-bottom: 3px solid #2c5179;
      @include MyriadProBold;
    }
    &:hover {
      border-bottom: 3px solid #7d97ad;
    }
  }
}
.tab-content {
  display: none;
  &.active {
    display: block;
  }
}

.col-centered {
  margin: 0 auto;
  float: none;
}
