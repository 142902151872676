.well {
    background: white;
    border: none;
    box-shadow: none;
    padding: 46px 0; 
    margin: 0;
    border-radius: 0;
    @include responsive(screen-phone) {
        padding: 24px 0;
    }
   &.segment {
       background: #f6f8f8;
       padding: 32px 0;
        @include responsive(screen-phone) {
            padding: 24px 0;
        }
       h2 {
            @include MyriadPro;
            text-align: center;
            @include responsive(screen-phone) {
                text-align: left;
            }
       }
   }
    &.partners {
        position: relative;
        padding: 32px 0;
        h2 {
            text-align: center;
            margin-bottom: 25px;
            @include MyriadPro;
            @include responsive(screen-phone) {
                margin-bottom: 24px;
            }
        }
        .col-sm-3 {
            margin: 0 auto;
        }
        a {
            display: block;
            overflow: hidden;
            height: 85px;
            margin-bottom: 24px;
            position: relative;
            transition: all .3s;
            @include responsive(screen-phone) {
                margin-bottom: 0;
            }
            &:hover {
                background: #f6f8f8;
            }
            img {
                margin: 0 auto;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                max-width: 130px;
                @include responsive(screen-phone) {
                    width: auto;
                    max-width: 70%;
                }
            }
            &.btn {
                height: auto;
                margin-top: 0;
                @include responsive(screen-phone) {
                    margin-top: 16px;
                    width: 100%;
                }
            }
        }
    }
    &.video, &.live {
        background: white;
        padding-bottom: 0;
        h2 {
            margin-bottom: 30px;
        }
    }  
    &.contact {
        background: #eff2f3;
        border-top: 1px solid #ccc;
        padding: 32px 0; 
        position: relative;    
        img {
            float: left;
            width: 170px;
            height: 170px;
            border-radius: 50%;
        }
        .content {
            @include responsive(screen-from-tablet) {
                padding-left: 32px;
            }
            h3 {
                @include MyriadProBold;
                font-size: 24px;
                position: relative;
                margin-bottom: 25px;   
            }
            p {
                margin-bottom: 0;
                line-height: 24px;
                font-size: 16px;
            }
            .phone {
                @include MyriadPro;
                color: #282828;
                position: relative;
                font-size: 18px;
                padding-left: 40px;
                margin: 24px 0;
                display: block;
                &:before {
                    background: url(../img/layout/icons/svg/call-answer.svg) no-repeat 0 3px;
                    @include ab-center;
                    background-size: 22px;
                    width: 24px;
                    height: 27px;
                    right: auto;
                }
            }
            .mail-us {
                padding-left: 40px;
                position: relative;   
                @include MyriadPro;
                color: #282828;
                font-size: 18px;
                &:before {
                    background: url(../img/layout/icons/svg/close-envelope.svg) no-repeat 0 3px;
                    @include ab-center;
                    right: auto;
                    width: 24px;
                    height: 24px;
                }
                @include responsive(screen-phone) {
                    width: 100%;
                }
            }
        }
    }
    &.courses-overview {
        background: white;
        padding-top: 32px;
        .training-count {
            text-align: center;
            font-size: 18px;
        }
         .mobile-count {
            text-align: center;
            font-size: 18px;
             margin: -10px 0 25px 0;
        }
        .searchresults {
            font-size: 18px;
            color: black;
            @include MyriadProBold;
            padding-left: 0;
            margin-bottom: 10px;
        }
    }
    &.content {
        padding-top: 0;
        .container {
            position: relative;
            &:before {
                background: url("../img/layout/icons/content-bg.png") no-repeat;
                content: "";
                position: absolute;
                left: -55px;
                top: 90px;
                width: 276px;
                height: 231px;
            }
        }
        a {
            color: #77758a;
            text-decoration: underline;
            &:hover {
                color: black;
            }
        }
        h3 {
            font-size: 22px;
            margin-bottom: 5px;
            @include responsive(screen-phone) {
                font-size: 18px;
            }
        }
        h4 {
            @include MyriadProBold;
            font-size: 18px;
            margin-bottom: 5px;
            @include responsive(screen-phone) {
                font-size: 14px;
            }
        }
        h5 {
            @include MyriadProBold;
            font-size: 18px;
            margin-bottom: 5px;
            text-transform: none;
            @include responsive(screen-phone) {
                font-size: 14px;
            }
        }
        p {
            font-size: 16px;
            line-height: 30px;
        }
    }
    &.pricing {
        background: white;
        padding: 32px 0 32px 0;
        .row {
            padding: 0 1px;
        }
    }
    &.footer {
        background-color: #222;
        color: $white;
        position: relative;
        .container {
            overflow: hidden;
        }
        h6 {       
            font-size: 14px;     
            margin-top: 12px;
            @include MyriadProBold;
            margin-bottom: 20px;
        }  
    }

    &.sell {
        background-color: $highlight-color;
        color: $white;
        padding: 35px 0 70px;
    }

    &.search {
        background-color: $search-bar-color;
        color: $white;
        padding: 20px 0;

        h3 {
            margin: 0;
        }
    }

    &.courses-sell {
        background: $gray-light3;
        padding: 40px 0 60px;
    }

    &.bordered {
        background-color: $white;
        padding-top: 0;
        padding-bottom: 0;

        .container {
            padding: 0 15px;
        }
        h3 {
            margin-bottom: 10px;
        }
    }
    &.default-heading {
        background: white;
        padding: 35px 0;

        h2 {
            color: black;
            font-size: 21px;
            @include MyriadProBold;
            margin-top: 30px;
        }
    }
    &.teaser {
        padding: 0;
        overflow: hidden;
        @include responsive(screen-tablet) {
            overflow: hidden;
        }
    }
    &.thankyou {
        padding-bottom: 0;
        h2 {
            margin: 0;
        }
    }
}
