﻿.c-flag {
  width: 30px;
  height: 20px;
  margin-right: 15px;
}

.c-dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;

  &:hover {
    .c-dropdown-content {
      opacity: 1;
      visibility: visible;
    }
  }

  //display: none; // TODO:Remove this coment when the language switch goes to prod
}

.c-dropdown .c-button--small {
  @include responsive-custom(0, 1024px) {
    width: 100%;
  }
}

.c-dropdown-content {
  div {
    margin: 0;
  }

  display: none;
  position: absolute;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

  z-index: 1;

  ul {
    padding-left: 0px;
    margin-bottom: 0;
  }
}

.c-dropdown-content {
  li {
    display: block;
    transition: background 300ms ease;
    background-color: $white;

    &:hover {
      background-color: $white-smoke;

      a {
        color: $black;
      }
    }

    a {
      font-family: "MyriadProBold";
      font-size: 0.8em;
      color: $text-color-primary;
      display: block;
      padding: 10px 10px;
      margin-top: 0px;
      text-decoration: none;
      text-align: left;
    }
  }
}

.c-dropdown:hover .c-dropdown-content {
  display: block;
}

// User Perfil
.c-dropdown.c-dropdown-userperfil {
  margin-left: -5px;
  margin-right: -5px;

  .c-dropdown-selected {
    padding: 0px 5px;
  }

  .c-dropdown-description.c-dropdown-description.c-dropdown-description {
    min-height: inherit;
    float: none;
    display: flex;
    align-items: center;
  }

  .c-dropdown-description.c-dropdown-description.c-dropdown-description::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 5px solid black;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    margin-left: 10px;
  }

  .c-dropdown-content {
    background-color: rgba(13, 44, 68, 0.6);

    ul {
      margin-bottom: 0px;
    }

    a {
      padding: 12px 5px;
    }
  }
}

.c-dropdown-color-black {
  color: black;
}

.form-group {
  .c-dropdown {
    .c-button {
      text-transform: capitalize;
    }

    .c-language-selector {
      margin-bottom: 0;
    }
  }
}

.c-login-wrapper {
  max-width: 650px;
  width: 100%;
  margin: 0 auto;

  .c-login {
    max-width: 100%;
  }
}

.align-self-center {
  display: flex;
  align-items: center;
}

/* Utility classes */

.u-margin-top-10 {
  margin-top: 10px;
}
.u-margin-top-20 {
  margin-top: 20px;
}

.u-margin-top-40 {
  margin-top: 40px;
}

.u-margin-bottom-20 {
  margin-bottom: 20px;
}

.u-margin-bottom-40 {
  margin-bottom: 40px;
}

.u-margin-left-20 {
  margin-left: 20px;
}

.u-margin-right-20 {
  margin-right: 20px;
}

.o-separator--word {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid $gray;
  line-height: 0.1em;
  margin: 50px 0 50px;

  span {
    background: $white;
    padding: 0 10px;
  }
}

.o-language-switch {
  display: flex;
  align-items: center;
}

.o-language__arrow {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid darken($color-primary, 10%);
  margin-left: 5px;
}

.o-language-flag {
  height: 100%;
  width: auto;
  max-height: 22px;
  max-width: 22px;
  margin-right: 5px;
}

.o-language-selector {
  display: flex;
  flex-direction: column;
  min-width: 120px;
}

.row.vertical-divider {
  overflow: hidden;
}
.row.vertical-divider > div[class^="col-"] {
  padding-bottom: 100px;
  margin-bottom: -100px;
  border-left: 1px solid $gray;
  border-right: 1px solid $gray;
}
.row.vertical-divider div[class^="col-"]:first-child {
  border-left: none;
}
.row.vertical-divider div[class^="col-"]:last-child {
  border-right: none;
}

.o-background-image {
  background-position: center right;
  // background-image: url("../../../img/layout/OA_Header_Banner.jpg");
  background-repeat: no-repeat;
  background-color: $color-secondary;
  padding: 130px 0 60px 0;
  background-size: cover;
}

.layer {
  background-color: rgba(248, 247, 216, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.noa-content-wrapper {
  padding: 60px 0;
}

.u-display-block {
  display: block;
}
