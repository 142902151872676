﻿$input-dimension: 36px;

.c-checkbox-filters {
    margin: 30px auto;
    text-align: center;

    input[type="checkbox"] {
        display: none;

        &:checked + label {
            color: $brand-blue;
             
            &:after {
                transform: translateY(-50%) scale(1);
            }
        }
    }
}

.c-checkbox-filters__item {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding-left: 55px;
    line-height: $input-dimension;

    &:not(:nth-of-type(1)) {
        margin-left: 30px;
    }

    @include responsive-custom(0px, 768px) { 
        text-align: left;
    }

    @include responsive-custom(441px, 768px) {
        width: calc(100% / 2 - 2px);

        &:nth-last-child(1),
        &:nth-last-child(2) {
            margin-top: 20px;
        }

        &:not(:nth-of-type(1)) {
            margin-left: 0px;
        }
    }

    @include responsive-custom(0px, 440px) {
        width: 100%;

        &:not(:nth-of-type(1)) {
            margin-top: 20px;
            margin-left: 0;
        }

    }


    label {
        cursor: pointer;
        margin: 0;
        font-size: 18px;
        font-weight: 400;

        &:before,
        &:after {
            content: '';
            position: absolute;
            transition: all .3s ease;
            cursor: pointer;
            left: 0;
            width: $input-dimension;
        }

        &:before {
            top: 0;
            height: $input-dimension;
            background-color: #f1f1f1;
        }

        &:after {
            height: 18px;
            top: 50%;
            background: transparent url('../img/layout/icons/icon-check.png') no-repeat center center;
            background-size: 24px;
            transform: translateY(-50%) scale(0);
        }
    }
}