﻿.c-button {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 5px;
  overflow: hidden;

  text: {
    decoration: none;
    align: center;
  }

  border: {
    width: 2px;
    style: solid;
  }

  transition: all 300ms ease;
  @include MyriadProBold;

  &:after {
    content: "";
    position: absolute;
    width: 30px;
    height: 110px;
    left: -70px;
    top: -35px;
    transform: rotateZ(45deg);
    background-color: rgba(255, 255, 255, 0.3);
    filter: blur(10px);
    transition: all 250ms ease-in-out;
  }

  &:hover {
    transition-delay: 100ms;

    &:after {
      left: calc(100% + 45px);
    }
  }

  &:hover,
  &:visited,
  &:focus {
    color: #ffffff;
    text-decoration: none;
  }
}

.c-button--red {
  color: #ffffff;
  background-color: $brand-red;
  border-color: $brand-red;

  &:hover {
    border-color: transparent;
    background-color: rgba($brand-red, 0.8);
  }
}

.c-button--transparent {
  color: #ffffff;
  border-color: #ffffff;
  background-color: transparent;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    border-color: transparent;
  }
}

.c-button--blue-trans {
  color: $brand-blue;
  border-color: $brand-blue;
  background-color: transparent;

  &:visited {
    color: $brand-blue;
  }

  &:focus {
    color: $brand-blue;
  }

  &:hover {
    @include responsive(screen-desktop) {
      border-color: transparent;
      background-color: rgba($brand-blue, 0.8);
      color: white;
    }
  }
}

.c-button--blue {
  color: #ffffff;
  border-color: $brand-blue;
  background-color: $brand-blue;

  &:hover {
    border-color: transparent;
    background-color: rgba($brand-blue, 0.8);
  }
}

.c-button--yellow {
  color: #000000;
  border-color: transparent;
  background-color: $brand-yellow;

  &:visited {
    color: #000000;
  }

  &:hover {
    color: #000000;
    border-color: transparent;
    background-color: rgba($brand-yellow, 0.8);
  }
}

.c-button--white-trans {
  color: #ffffff;
  border-color: #ffffff;
  background-color: transparent;

  &:hover {
    color: $brand-red;
    background-color: #ffffff;
  }
}

.c-button--initial {
  background-color: #ffffff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.5);
  border-radius: 0;
  border: none;
  color: #727272;

  &:focus,
  &:visited {
    color: #727272;

    &:hover {
      color: #ffffff;
    }
  }

  &.c-button--confirm {
    &:hover {
      background-color: #0bb61e;
      border-radius: 3px;
    }
  }

  &.c-button--cancel {
    &:hover {
      background-color: #f44336;
      border-radius: 3px;
    }
  }
}

.c-button--wide {
  width: 100%;
}

.c-button--large {
  line-height: 60px;
  padding: 10px 20px;
  font-size: 20px;
}

.c-button--med {
  padding: 0 40px;
  line-height: 60px;
  font-size: 24px;
}

.c-button--small {
  padding: 0 30px;
  line-height: 44px;
  font-size: 16px;
}

.c-button--small-dynamic {
  padding: 0px 30px;
  line-height: 44px;
  font-size: 16px;
  margin: 5px 2px;
}

.c-button--fixed-width {
  min-width: 140px;
}

.c-button--dropdown {
  display: block;
  font-size: 0.8em;
  padding: 0 11px;
  height: 40px;
  line-height: 35px;
  min-width: 140px;
}

.c-mobile-buttons {
  display: none;

  @include responsive-custom(0px, 1024px) {
    display: block;
    margin-bottom: 50px;
  }
}

.c-button--xs {
  font-size: 12px;
  padding: 10px 40px;
}

.c-button--mobile {
  display: none;

  @include responsive-custom(1px, 1024px) {
    display: block;
  }
}

.c-button--phone-icon {
  padding: 10px 40px 10px 55px;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    @include transform(rotate(0deg) translateY(-50%));
    left: 16px;
    background: url(../img/layout/icons/phone-small.png) no-repeat;
    width: 17px;
    height: 18px;
    transition: all 300ms ease;
  }

  &:hover {
    &:before {
      left: 20px;
      -webkit-animation: NAME-YOUR-ANIMATION 0.3s infinite; /* Safari 4+ */
      -moz-animation: NAME-YOUR-ANIMATION 0.3s infinite; /* Fx 5+ */
      -o-animation: NAME-YOUR-ANIMATION 0.3s infinite; /* Opera 12+ */
      animation: NAME-YOUR-ANIMATION 0.3s infinite; /* IE 10+, Fx 29+ */
      @-webkit-keyframes NAME-YOUR-ANIMATION {
        0% {
          @include transform(rotate(0deg) translateY(-50%));
        }

        20% {
          @include transform(rotate(4deg) translateY(-50%));
        }

        40% {
          @include transform(rotate(-4deg) translateY(-50%));
        }

        60% {
          @include transform(rotate(4deg) translateY(-50%));
        }

        80% {
          @include transform(rotate(-4deg) translateY(-50%));
        }

        100% {
          @include transform(rotate(0deg) translateY(-50%));
        }
      }

      @-moz-keyframes NAME-YOUR-ANIMATION {
        0% {
          @include transform(rotate(0deg) translateY(-50%));
        }

        20% {
          @include transform(rotate(4deg) translateY(-50%));
        }

        40% {
          @include transform(rotate(-4deg) translateY(-50%));
        }

        60% {
          @include transform(rotate(4deg) translateY(-50%));
        }

        80% {
          @include transform(rotate(-4deg) translateY(-50%));
        }

        100% {
          @include transform(rotate(0deg) translateY(-50%));
        }
      }

      @-o-keyframes NAME-YOUR-ANIMATION {
        0% {
          @include transform(rotate(0deg) translateY(-50%));
        }

        20% {
          @include transform(rotate(4deg) translateY(-50%));
        }

        40% {
          @include transform(rotate(-4deg) translateY(-50%));
        }

        60% {
          @include transform(rotate(4deg) translateY(-50%));
        }

        80% {
          @include transform(rotate(-4deg) translateY(-50%));
        }

        100% {
          @include transform(rotate(0deg) translateY(-50%));
        }
      }

      @keyframes NAME-YOUR-ANIMATION {
        0% {
          @include transform(rotate(0deg) translateY(-50%));
        }

        20% {
          @include transform(rotate(4deg) translateY(-50%));
        }

        40% {
          @include transform(rotate(-4deg) translateY(-50%));
        }

        60% {
          @include transform(rotate(4deg) translateY(-50%));
        }

        80% {
          @include transform(rotate(-4deg) translateY(-50%));
        }

        100% {
          @include transform(rotate(0deg) translateY(-50%));
        }
      }
    }
  }
}

// Button click animation

.c-button--animation {
  overflow: hidden;
}

.ink {
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  transform: scale(0);
}

.animate {
  -webkit-animation: ripple 0.65s linear;
  -moz-animation: ripple 0.65s linear;
  -ms-animation: ripple 0.65s linear;
  -o-animation: ripple 0.65s linear;
  animation: ripple 0.65s linear;
}

@-webkit-keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

@-moz-keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

@-o-keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

//2020 Redesign Buttons
//this is a placeholder for the following buttons
.o-button {
  display: inline-block;
  @include MyriadPro;
  font-size: 16px;
  padding: 10px 20px !important;
  text-transform: none !important;
  border-radius: $buttons-border-radius;
  text-align: center;
  cursor: pointer;
}

.o-button-primary {
  @extend .o-button;
  background-color: $color-secondary;
  color: $black;
  border: 2px solid $color-secondary;

  &:hover:not([disabled]) {
    background-color: desaturate($color-secondary, 20%);
    color: $black;
    border: 2px solid $color-secondary;
  }

  &[disabled] {
    background-color: desaturate($color-secondary, 40%);
    color: desaturate($black, 30%);
    border-color: desaturate($color-secondary, 40%);
    pointer-events: none;
  }

  &:hover[disabled] {
    background-color: $gray-dark1;
  }

  &:active,
  &:focus,
  &:visited {
    background-color: $color-secondary;
    border: 2px solid $color-secondary;
    color: $black;
  }
}

.o-button-outline {
  @extend .o-button;
  background-color: transparent;
  border: 2px solid $black;
  color: $black;

  &:hover:not([disabled]) {
    background-color: $black;
    border: 2px solid $black;
    color: $white;
  }

  &[disabled] {
    background-color: $gray-dark1;
    color: desaturate($black, 10%);
    border-color: desaturate($black, 30%);
    pointer-events: none;
  }

  &:hover[disabled] {
    background-color: $gray-dark1;
  }
}

.o-button--full-width {
  width: 100%;
}

.o-wrapper-buttons--mobile {
  text-align: center;
  .o-button-primary {
    width: 100%;
    max-width: 240px;
    margin-bottom: 20px;
  }

  @include responsive-custom(0px, 1024px) {
    .o-button-primary {
      max-width: auto;
    }
  }
}
