// Import custom Bootstrap variables
@import "bootstrap-custom";

//Import theme
@import "mixins/mixins";
@import "standard/standard";
@import "custom/custom";
@import "bootstrap/bootstrap";
@import "page/page";

@import "base";
@import "adminusers";

@import "new-template/style"; 