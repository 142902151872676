﻿.register {
    background: #f5f5f5;
    background: -moz-linear-gradient(top, #f5f5f5 0%, #fff 100%);
    background: -webkit-linear-gradient(top, #f5f5f5 0%, #fff 100%);
    background: linear-gradient(to bottom, #f5f5f5 0%, #fff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#ffffff',GradientType=0 );
    h5 {
        @include MyriadProBold;
        margin-top: 16px;
    }
    .intro {
        background: white;
        &:after {
            border-color: white transparent transparent transparent
        }
        &:before {
            display: none;
        }
        .btn-secondary {
            @include responsive(screen-phone) {
                text-align: center;
                display: block;
                width: 100%;
                margin-top: 30px;
            }
        }
    }
    .btn-secondary {
        margin: 5px 20px 0 0;
        float: left;
    }
    .disclaimer {
        float: left;
        margin-top: 20px;
        font-size: 14px;
    }
    .gender {
        display: block;
        margin-top: -7px;
        @include responsive(screen-phone) {
            margin-top: 0;
        }
    }
    &.user {
        .tile .row {
            margin-bottom: 0;
            &.has-error {
                margin-bottom: 20px;
            }
        }
        .tile .row>* {
            margin-bottom: 0;
        }
    }
    .noa-form .checkbox-button {
        cursor: initial;
        padding-left: 0;
        label {
            cursor: pointer;
            float: left;
            width: 90%;
            span {
                user-select: none;
                display: block;
                padding-top: 2px;
            }
            @include responsive(screen-phone) {
                line-height: 24px;
            }
            &.error-msg {
                position: relative;
            }
        }
        input[type="checkbox"] {
            display: block;
            float: left;
            margin-right: 12px;
            &:checked + label, &:checked + label span {
                background: transparent;
                color: #333;
            }
        }
    }
}