﻿/*.c-footer {
    z-index: 1;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%; 
    background-color: $brand-blue;
    padding: 70px 0 140px;

    @include responsive-custom(1px, 1020px) {
        position: relative;
        height: auto;
    }

    @include responsive-custom(1px, 540px) {
        padding: 40px 0;
    }

    h2,
    a {
        display: block;
        color: #ffffff;
    }

    h2 {
        line-height: 1.3em;

        @include responsive-custom(1px, 560px) {
            font-size: 20px;
        }

        a {
            line-height: 1;
            display: inline-block;

            &:not(:first-child) {
                color: #c4c7df;
                text-decoration: underline;
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;

            &:not(:first-child) {
                margin-left: 50px;

                @include responsive-custom(0px, 560px) {
                    margin-left: 0;
                    margin-top: 10px;
                }
            }

            @include responsive-custom(0px, 560px) {
                display: block;
            }
        }

        a {
            font-size: 16px;

            &:not(:first-child) {
                margin-top: 25px;

                @include responsive-custom(1px, 560px) {
                    margin-top: 10px;
                }
            }

            @include responsive-custom(1px, 560px) {
                padding-bottom: 10px;
                border-bottom: 1px solid #ffffff;
            }
        }
    }
}*/

/*.
    
    footer-links {
    padding: 0;
    list-style: none;
    font-size: 14px;
    @include responsive(screen-phone){
        margin-bottom: 0;  
    }
    @include responsive(screen-tablet){
        margin-bottom: 0;  
    }
     @include responsive(screen-from-tablet){
        float: left;
        width: 50%;
    }
    li {
        margin-bottom: 3px;
    }
    a {
        color: white;
        position: relative;
        &.footer-phone {
            padding-left: 32px;
            @include responsive(screen-phone){
                border: 1px solid white;
                padding: 14px;
                width: 100%;
                text-align: center;
                margin-top: 30px;
                float: left;
                width: 100%;
                font-size: 20px;
                text-decoration: none;
                &:hover {
                    background: white;
                    color: #595960;
                    @include responsive(screen-phone){
                        &:before {
                            background: url(../img/layout/icons/svg/call-answer.svg) no-repeat top left;
                            background-size: 18px;
                        }
                    }
                }
            }
            &:before {
                background: url(../img/layout/icons/svg/call-answer-2.svg) no-repeat top left;
                background-size: 13px;
                @include ab-center;
                right: auto;
                top: 3px;
                left: 2px;
                width: 13px;
                height: 16px;
                @include responsive(screen-phone){
                    background: url(../img/layout/icons/svg/call-answer-2.svg) no-repeat top left;
                    background-size: 18px;
                    left: 30px;
                    width: 18px;
                    height: 19px;
                }
            } 
        }
        &.footer-mail {
            padding-left: 32px;
            @include responsive(screen-phone){
                border: 1px solid white;
                padding: 14px;
                width: 100%;
                text-align: center;
                float: left;
                width: 100%;
                margin: 20px 0;
                font-size: 20px;
                text-decoration: none;
                 &:hover {
                    background: white;
                    color: #595960;
                    @include responsive(screen-phone){
                        &:before {
                            background: url(../img/layout/icons/svg/close-envelope.svg) no-repeat top left;
                            background-size: 22px;
                        }
                    }
                }
            }
            &:before {
                background: url(../img/layout/icons/svg/close-envelope-2.svg) no-repeat top left;
                background-size: 16px;
                @include ab-center;
                right: auto;
                width: 18px;
                height: 14px;
                @include responsive(screen-phone){
                    background: url(../img/layout/icons/svg/close-envelope-2.svg) no-repeat top left;
                    background-size: 22px;
                    left: 30px;
                    width: 24px;
                    height: 18px;
                }
            } 
        }
    }
}*/