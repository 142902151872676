﻿.intro.faq {
    a {
        color: $brand-primary;
    }
}

.category-block {
    margin-bottom: 46px;
    h2 {
        margin-bottom: 24px;
    }
    h3 {
        @include MyriadPro;
        line-height: 25px;
        cursor: pointer;
        font-size: 18px;
        color: #333;
        margin-bottom: 0;
        position: relative;
        padding-left: 32px;
        -webkit-touch-callout: none;
        -webkit-user-select: none; 
        -moz-user-select: none; 
        -ms-user-select: none;
        user-select: none;
        border-bottom: 1px solid #ccc;
        padding: 12px 0 12px 32px;
        &.toggled {
            margin-bottom: 0;
            border-bottom: 0;
            @include MyriadProBold;
            &:before {
                transform: rotate(270deg);
                top: 12px;
                left: 7px;
            }
        }
        &:hover {
            text-decoration: underline;
        }
        &:before {
            position: absolute;
            content: "";
            top: 20px;
            left: 0;
            background: url(../img/layout/icons/svg/small-arrow.svg) no-repeat;
            transform: rotate(90deg);
            background-size: 12px;
            width: 20px;
            height: 20px;
        }
    }
    span {
        display: none;
        font-size: 16px;
        width: 100%;
        padding-right: 300px;
        margin-bottom: 32px;
        padding-left: 32px;
        @include responsive(screen-tablet) {
            padding-right: 0;
        }
        @include responsive(screen-phone) {
            padding-right: 0;
        }
        &.toggled {
            border-bottom: 1px solid #ccc;
            padding-bottom: 12px;
            margin-bottom: 0;
        }
    }
}