﻿.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: $header-height;

  > li {
    list-style: none;
    padding-left: 20px;
  }

  @include responsive-custom(0, 1024px) {
    max-height: $header-height-small;
  }
}

.header__navigation--left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include responsive-custom(0px, 1024px) {
    flex-grow: 1;
  }
}

.c-logo {
  width: 200px;
  height: $header-height;
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  transition: all 300ms ease;

  @include responsive-custom(0px, 1024px) {
    max-height: $header-height-small;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }
}
