﻿.p-relative {
    position: relative;
}

.v-align {
    top: 50%;
    transform: translateY(-50%);
}

.reset-v-align {
    top: 0;
    transform: translateY(0);
}

.ta-center {
    text-align: center;
}
 
.ta-left {
    text-align: left;
}

.f-left {
    float: left;
}

.f-right {
    float: right;
}

.h-100 {
    height: 100%;
}

.mt-20 {
    margin-top: 20px;
}

.mr-50 {
    margin-right: 50px;
}

.z-2 {
    z-index: 2;
}

.bg-gray {
    background-color: #f4f4f4;
}

.disable-scroll {
    overflow: hidden !important;
}


@mixin transform($value) {
    -webkit-transform: $value;
    -ms-transform: $value;
    -o-transform: $value;
    transform: $value;
}


@mixin clearfix {
    clear: both;

    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}

.clearfix {
    @include clearfix;
}
