﻿.c-courses-slider {
    position: relative;
    width: 100%;
    padding: 20px 40px;
    text-align: center;

    .c-button {
        margin: 40px auto 0;
    }

    .owl-stage-outer {
        padding: 15px 0;
    }


    .owl-prev,
    .owl-next {
        font-size: 0;
        color: transparent;
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
        width: 25px;
        height: 54px;
        transition: all 300ms ease;
        background-size: 47px;
        background-repeat: no-repeat;
        background-image: url('../img/layout/icons/slider-arrows.png');
    }

    .owl-prev {
        left: -40px;
        background-position: 0 0;

        &:hover {
            @include transform(translateY(-50%) translateX(-5px));
        }

        @include responsive-custom(0, 1020px) {
            left: -25px;
        }
    }

    .owl-next {
        right: -40px;
        background-position: 100% 0;

        &:hover {
            @include transform(translateY(-50%) translateX(5px));
        }

        @include responsive-custom(0, 1020px) {
            right: -25px;
        }
    }

    .owl-dots {
        text-align: center;
        -webkit-tap-highlight-color: transparent;

        .owl-dot {
            display: inline-block;
            zoom: 1;

            &.active {
                span {
                    background-color: #657285;
                }
            }
        }

        span {
            width: 10px;
            height: 10px;
            margin: 5px 7px;
            background-color: #D6D6D6;
            display: block;
            -webkit-backface-visibility: visible;
            transition: opacity .2s ease;
            border-radius: 30px;
        }
    }
}
