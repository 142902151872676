.description-wrapper {
	.description {
        color: $white;
        margin: 40px 0 20px;

        h1 {
            font-size: 24px;            

            @include responsive(screen-from-tablet){
                font-size: 44px;
            }

            @include responsive(screen-desktop){
                font-size: 50px;
			    margin-bottom: 20px;
            }
        }
	}
}

.description {
	text-align: left;	
	p {
		font-size: 12px;
	}
	h1 {
		margin-bottom: 10px;
		font-size: 50px;
	}
}

.membership-header {
	.row {
		margin: 0;
	}
	*[class^="col-"] {
		&:not(:first-child):not(:last-child){
			border-bottom: 4px solid $gray-light3;
			margin: 0;
			padding: 0;
		}
		&:first-child {
			& + * {
				.header-item {
					text-align: left;
				}
			}
		}
	}
	h3 {
		margin: 0;
	}
	.header-item {
		font-family: sans-serif;
		font-size: 24px;
		text-transform: uppercase;
		text-align: center;
		border-bottom: 4px solid $gray-light3;
		margin: 0 0 -4px;
		height: 67px;
		span {
			font-weight: bold;
		}
		small {
			font-family: sans-serif;
            font-weight: bold;
			font-size: 12px;
			color: $highlight-color;
			display: block;
			margin-top: 15px;
		}
		&.month {
			border-color: $gray-light4;
		}
		&.year {
			border-color: $highlight-color;
		}
	}
}

.membership-item {
	margin-top: 5px;
	&:first-child {
		margin: 0;
	}
	&.full {
		.price {
			font-family: sans-serif;
		}
		.row {
			background: $highlight-color-light;
		}
	}
	.btn {
		line-height: 20px;
		padding-top: 12px;
		padding-bottom: 12px;
		display: none;
		&:hover {
			background-color: $highlight-color-hover;
		}
	}
	.row {
		margin: 0;
		font-size: 0;
		background: $gray-light1;
	}
	*[class^="col-"] {
		&:not(:first-child){
			margin: 0;
			padding: 20px 0;
			display: inline-block;
			vertical-align: middle;
			float: none;
			font-size: 10px;
			line-height: 16px;
			text-align: center;
			&:hover {
				.btn {
					display: inline-block;
				}
				.price {
					display: none;
				}
			}
		}
		&:nth-child(3){
			text-align: left;
		}
	}
	p {
		margin: 0;
	}
	.membership-info {
		padding: 0 20px;
	}
	img {
		margin: 0 auto;
		@include grayscale-mode(100%);
	}
	.price {
		font-family: sans-serif;
		color: $highlight-color;
		font-size: 24px;
		line-height: 44px;
		display: block;
		&.year {
			font-family: sans-serif;
            font-weight: bold;
			line-height: 44px;
			display: block;
		}
	}
	.membership-full {
		font-family: sans-serif;
        font-weight: bold;
		font-size: 24px;
		color: $black;
		text-transform: uppercase;
		display: block;
		padding: 15px 0;
	}
	&:hover {
		.row {
			background: $gray-light3;
		}
		img {
			@include grayscale-mode(0);
		}
	}
}

.membership-plan {
	position: relative;
	.faux-column {
		@include ab-center;
		background: $white;
		&.right {
			left: auto;
		}
	}
}

.disclaimer {
	font-size: 10px;
	line-height: 16px;
	margin-top: 15px;
}

.show-more-elements {
	margin-top: 25px;
}
