﻿.c-searchbox {
    position: relative;
    margin: 20px auto 0;
    max-width: 620px;
    width: calc(100% - 20px);

    .c-searchbox__input {
        width: 100%;
        border-radius: 4px;
        border: none;
        padding: 20px 90px 20px 30px;
        color: #000000;
        background: #ffffff url(../img/layout/icons/svg/search.svg) no-repeat;
        background-size: 30px;
        background-position: calc(100% - 30px) 50%;
    }

    .c-searchbox__results {
        top: calc(100% - 3px);
        text-align: left;
        box-shadow: 0px 8px 12px -9px rgba(0,0,0,0.5);

        a {
            padding: 4px 30px;
        }
    }
}
