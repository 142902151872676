﻿@mixin arrow($arrow-width, $arrow-height, $arrow-color){
        width: 0;
        height: 0;
        border-left: $arrow-width solid transparent;
        border-right: $arrow-width solid transparent;
        border-top: $arrow-height solid $arrow-color;
        position: absolute;
        left: 50%;
        bottom: -(($arrow-height) - 1px);
        margin-left: -($arrow-width);
        z-index: 1;
}

@mixin ab-center {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}