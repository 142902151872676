﻿.c-tooltip {
    position: relative;
    top: -10px;
    display: inline-block;
    color: #ffffff;
    cursor: pointer;

    @include responsive-custom(1px, 1020px) {
    }

    &:hover {
        .c-tooltip__info {
            opacity: 1;
            @include transform(translateX(0));
            visibility: visible;

            @include responsive-custom(1px, 500px) {
                @include transform(translateY(0) translateX(0));
            }
        }
    }

    .c-tooltip__icon {
        display: block;
        text-align: center;
        width: 15px;
        height: 15px;
        line-height: 15px;
        border-radius: 100%;
        background-color: $brand-blue;
        color: currentColor;
        font-size: 10px;
    }

    .c-tooltip__info {
        position: absolute;
        left: calc(100% + 15px);
        top: -13px;
        background-color: rgba($brand-blue, 0.95);
        width: 200px;
        border-radius: 4px;
        padding: 20px;
        visibility: hidden;
        opacity: 0;
        @include transform(translateX(5px));
        transition: all 300ms ease-in;
        font-size: 14px;

        @include responsive-custom(1px, 440px) {
            top: 35px;
            right: inherit;
            @include transform(translateY(5px) translateX(0));
        }

        &.c-tooltip__info-1 {
            @include responsive-custom(1px, 440px) {
                left: -120px;

                &:before {
                    left: 118px;
                }
            }
        }

        &.c-tooltip__info-2,
        &.c-tooltip__info-3 {
            @include responsive-custom(1px, 440px) {
                left: -60px;

                &:before {
                    left: 58px;
                }
            }
        }


        &:before {
            content: '';
            position: absolute;
            left: -6px;
            top: 10px;
            width: 0;
            height: 0;
            border-right: 6px solid rgba($brand-blue, 0.95);
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;

            @include responsive-custom(1px, 440px) {
                top: -16px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 6px solid rgba(63,66,96,0.95);
            }
        }

        a {
            display: inline-block;
            color: currentColor;
            text-decoration: dotted;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
