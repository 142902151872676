﻿.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

@media (min-width: 1020px) {
    .col-md-15 {
        width: 20%;
        float: left;
    }
}
.business {
   .pricing-block {
        padding: 34px 20px 20px 20px;
        background: #f6f8f8;
        border: 1px solid #f0f0f0;
        margin-bottom: 31px;
        text-decoration: none;
        display: block;
        @include responsive(screen-desktop) {
            margin-bottom: 20px;
            min-height: 276px;
        }
         @include responsive(screen-tablet) {
            min-height: 200px;
        }
        &:hover {
            box-shadow: 1px 1px 2px 0px #c9c9c9;
        }
        &.individual {
            background: $highlight-color;  
            border: 1px solid $brand-blue;
            h2, h3, p, span {
                color: white;
            }
            h2 {
                @include responsive(screen-desktop) {
                    margin-bottom: 42px;
                }
            }

        }
        h2 {
            text-align: center;
            margin-bottom: 20px;
            font-size: 21px;
        }
        p {
            color: black;
            text-align: center;
            font-size: 14px;
            margin-bottom: 0;
        }
        h3 {
            text-align: center;
            position: absolute;
            font-size: 17px;
            bottom: 20px;  
            text-transform: none;
            width: 100%;
            left: 0;
            @include responsive(screen-tablet) {
                font-size: 18px;
                bottom: 30px;
            }
            @include responsive(screen-phone) {
                position: relative;
                margin-top: 32px;
                bottom: 0;
            }
        }
        span {
            display: block;
            text-align: center;
            color: #767475;
            font-size: 13px;
            position: absolute;
            bottom: 40px;
            left: 0;
            width: 100%;
            @include responsive(screen-tablet) {
                font-size: 14px;
                bottom: 50px;
            }
             @include responsive(screen-phone) {
                position: relative;
                bottom: 0;
            }
        }
        .btn-default {
            margin-bottom: 20px;
            padding: 12px 0;
            width: 100%;
        }
        .call-back {
            width: 100%;
            margin-left: 0;
            position: relative;
            padding: 12px 0;
            &:before {
                @include ab-center;
                left: 16px;
                top: 6px;
                background: url(../img/layout/icons/phone-small.png) no-repeat;
                width: 17px;
                height: 18px;
                padding: 12px 0;
            }
            &:hover {
                background: transparent;
                color: #9194b5;
                &:before {
                    background: url(../img/layout/icons/phone-small-hover.png) no-repeat;
                }
            }
        }
    }
    .button-wrapper {
        position: relative;
        margin: 16px auto 0 auto;
        width: 554px;
        @include responsive(screen-phone) {
            width: 100%;
            .btn, .btn.call-back, .btn.btn-secondary {
                width: 100%;
                margin-bottom: 20px;
                margin-right: 0;
            }
        } 
        .btn {
            width: 264px;
            margin-right: 20px;
            margin-left: 0;
            &.call-back {
                padding-left: 66px;
                position: relative;
                &:before {
                    @include ab-center;
                    left: 16px;
                    right: auto;
                    background: url(../img/layout/icons/phone-small.png);
                    width: 17px;
                    height: 18px;
                }
                &:hover:before {
                    background: url(../img/layout/icons/phone-small-hover.png);
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
