﻿
.c-video-player {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.9);
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    z-index: 20;
    transition: all 300ms ease;
    &.is-visible

{
    opacity: 1;
    visibility: visible;
}

.btn-close-video {
    content: '';
    position: absolute;
    right: 20px;
    top: 20px;
    width: 50px;
    height: 50px;
    z-index: 2;
    transition: all 300ms ease;
    &:hover

{
    transform: rotate(-90deg);
}

&:before,
&:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    background-color: #ffffff;
}

&:before {
    @include transform(translateX(-50%) rotate(45deg));
}

&:after {
    @include transform(translateX(-50%) rotate(-45deg));
}

}

.c-video-player--holder {
    position: relative;
    width: 1024px;
    height: 576px;
    margin: 0 auto;
    top: 50%;
    @include transform(translateY(-50%));
    z-index: 1;

    @include responsive-custom(768px, 1024px) {
        width: 744px;
        height: 416px;
    }

    @include responsive-custom(0px, 768px) {
        width: 96%;
        height: 350px;
    }
}

iframe {
    width: 100%;
    height: 100%;
}

}

.c-iframe {
    position: relative;
    height: calc(100vh - 280px);
    overflow: hidden;
    iframe

{
    border: none;
}

.c-banner__small {
    height: 160px;
}

@include responsive-custom(0, 1020px) {
    height: 100vh;
    padding-top: 52px;
}
}
