﻿.c-tabs {
    &.c-tabs--red-arrow {
        a {
            &:after {
                border-bottom: 30px solid $brand-red;
            }
        }
    }

    &.c-tabs--white-arrow {
        a {
            &:after {
                border-bottom: 30px solid #ffffff;
            }
        }
    }

    &.c-tabs--big {
        .c-tabs__header--link {
            padding: 60px 20px;
        }
    }

    &.c-tabs--med {
        .c-tabs__header--link {
            padding: 40px 20px;
        }
    }

    .c-tabs__header {
        background-color: $brand-blue;
        text-align: center;
        overflow: hidden;

        .c-tabs__header-links {
            display: table;
            width: 100%;
            max-width: 1200px;
            margin: 0 auto;
            table-layout: fixed;

            .c-tabs__header--link {
                position: relative;
                color: $brand-blue-gray;
                display: table-cell;
                vertical-align: middle;
                font-size: 24px;
                @include MyriadProBold;
                transition: all 300ms ease;
                line-height: 1.4em;

                @include responsive-custom(320px, 1100px) {
                    display: block;
                    line-height: 30px;
                    font-size: 20px;
                    padding: 30px 20px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    width: 0;
                    height: 0;
                    border-left: 26px solid transparent;
                    border-right: 26px solid transparent;
                    opacity: 0;
                    @include transform(translateY(53px) translateX(-50%));
                    transition: all 300ms cubic-bezier(0.3,1.1,0.6,1.3);

                    @include responsive-custom(320px, 1100px) {
                        display: none;
                    }
                }

                &:hover:not(.is-active) {
                    color: rgba(255,255,255, 0.7);

                    &:after {
                        opacity: 1;
                        @include transform(translateY(15px) translateX(-50%));
                    }
                }
            }
        }

        .c-tabs__header--link {
            &.is-active {
                color: #ffffff;
                background-color: rgba($brand-blue-gray, 0.2);

                &:after {
                    opacity: 1;
                    @include transform(translateY(2px) translateX(-50%));
                }
            }
        }
    }

    .c-tabs__holder {
        position: relative;
        padding: 65px 0;
        height: 650px;
        background-color: $brand-red;
        overflow: hidden;

        @include responsive-custom(401px, 1100px) {
            height: 850px;
            padding: 20px 10px;
        }

        @include responsive-custom(1px, 400px) {
            height: 800px;
            padding: 20px 10px;
        }
    }

    .c-tabs__content {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        @include transform(translateY(0px));
        text-align: center;
        transition: all 300ms ease;
        opacity: 0;
        visibility: hidden;
        transition-delay: 0ms;

        &.active {
            visibility: visible;
            opacity: 1;
            @include transform(translateY(-50%));
            transition-delay: 100ms;
        }

        h1,
        h2,
        p,
        span {
            color: #ffffff;
        }

        h2 {
            font-size: 36px;
            margin-bottom: 50px;
            @include MyriadProBold;

            @include responsive-custom(320px, 768px) {
                font-size: 24px;
            }
        }

        p {
            width: 100%;
            font-size: 24px;
            line-height: 1.4em;

            @include responsive-custom(320px, 768px) {
                font-size: 18px;
            }
        }

        .c-tabs__content-text {
            margin: 0 auto;
            max-width: 670px;
            width: 100%;
        }

        .c-button {
            margin-top: 30px;
        }
    }

    .c-tabs__content-item {
        float: left;
        width: 50%;
    }

    .c-tabs__content-item-text {
        text-align: left;

        .c-button {
            margin-top: 20px;
        }
    }

    .c-tab-list {
        padding: 0;
        list-style: none;
        display: table;

        @include responsive-custom(320px, 1100px) {
            width: 100%;
            margin: 15px auto 0;
        }


        li {
            display: table-cell;
            vertical-align: top;

            div {
                text-align: left;
                min-height: 60px;

                @include responsive-custom(768px, 1100px) {
                    width: 33%;
                    float: left;
                    min-height: 80px;
                }

                @include responsive-custom(320px, 768px) {
                    width: 100%;
                    min-height: inherit;
                    border-bottom: 1px solid #ffffff;
                    padding: 10px 0;
                }

                &:not(:first-child) {
                    margin-top: 35px;

                    @include responsive-custom(320px, 1100px) {
                        margin-top: 0;
                    }
                }

                figure,
                span {
                    vertical-align: middle;
                    display: inline-block;
                }


                figure {
                    display: inline-block;
                    margin-right: 20px;
                    width: 50px;
                }

                img {
                    width: auto;
                    max-height: 40px;
                    max-width: 50px;
                }

                span {
                    font-size: 24px;
                    @include MyriadProBold;
                    line-height: 1.3;
                    width: calc(100% - 90px);

                    @include responsive-custom(320px, 1100px) {
                        font-size: 16px;
                    }
                }
            }
        }

        &.c-tab-list--med {
            margin: 0px auto;
            width: 100%;
            max-width: 1366px;

            li {
                @include responsive-custom(320px, 1100px) {
                    display: block;
                    @include clearfix;
                }

                &:not(:first-child) {
                    padding-left: 40px;

                    @include responsive-custom(320px, 1100px) {
                        padding: 0;
                    }
                }
            }

            p {
                margin: 50px auto 0;
                text-align: left;
                max-width: 670px;

                a {
                    display: block;
                    margin-top: 40px;
                    text-decoration: underline;
                }
            }

            .c-button {
                margin-top: 70px;

                @include responsive-custom(320px, 768px) {
                    margin-top: 35px;
                }
            }
        }

        &.c-tab-list--small {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;

            li {
                @include responsive-custom(1px, 1024px) {
                    display: block;
                }

                &:not(:first-child) {

                    @include responsive-custom(1px, 1024px) {
                        margin-top: 20px;
                    }
                }
            }

            h1 {
                color: #ffffff;
                font-size: 26px;
                line-height: 1.4em;
                margin-bottom: 20px;

                @include responsive-custom(1px, 1024px) {
                    font-size: 20px;
                }
            }

            p {
                width: 100%;
                text-align: center;
                min-height: 270px;

                @include responsive-custom(1px, 1024px) {
                    min-height: inherit;
                    font-size: 16px;
                }
            }

            .c-button {
                @include responsive-custom(1px, 540px) {
                    width: 275px;
                    line-height: 50px;
                    font-size: 20px;
                    margin-top: 20px;
                }
            }
        }

        &.c-tab-list--xs {
            max-width: 1140px;
            width: 100%;
            margin: 0 auto;

            li {
                @include responsive-custom(320px, 1100px) {
                    display: block;

                    &:not(:first-child) {
                        margin-top: 35px;
                    }
                }

                span {
                    font-size: 24px;
                    @include MyriadProBold;
                }
            }
        }

        &.c-tab-video {
            li {

                &:not(:first-child) {
                    padding-left: 30px;
                }

                @include responsive-custom(320px, 1100px) {
                    text-align: center;

                    &:not(:first-child) {
                        padding-left: 0px;
                        margin-top: 30px;
                    }
                }
            }

            .video-img {
                display: block;

                img {
                    @include responsive-custom(1px, 500px) {
                        width: 100%;
                    }
                }
            }
        }
    }

    .c-tab-icon {
        position: relative;
        margin: 0 auto 30px;
        width: 220px;
        height: 220px;
        background-color: rgba(255,255,255,0.1);
        border-radius: 100%;

        @include responsive-custom(320px, 1100px) {
            width: 170px;
            height: 170px;
        }

        img {
            position: relative;
            top: 50%;
            @include transform(translateY(-50%));
        }
    }
}


// CUSTOM TABS

.c-custom-tab {
    position: relative;
    display: none;
    @include transform(translateY(10px));
    transition: all 300ms cubic-bezier(0.3,1.1,0.6,1.2);
    opacity: 0;

    &.is-active {
        display: block;
        @include transform(translateY(0));
        opacity: 1;
    }


    &.c-custom-tab--2 {
        &.is-active + .c-birth-date-holder {

            &.is-active {
                .form-label {
                    &:after {
                        content: '*';
                        display: inline-block;
                    }
                }
            }
        }
    }
}

.transp-hack {
    color: #ffffff;

    @media screen and (max-width: 768px) {
        display: none;
    }
}

.c-custom-tabs-holder {
    position: relative;
    @include transform(translateY(10px));
    margin-top: 20px;
    transition: all 300ms ease;
    display: none;

    &.is-active {
        background-color: #f9f9f9;
        padding: 0px 20px 35px;
        @include transform(translateY(0px));
        opacity: 1;
        display: block;
    }
}