@import "../custom-definitions";

.column-list {
    list-style: none;

    @include responsive(screen-phone) {
        padding: 0 15px;
    }

    li {
        font-size: 16px;
        padding: 10px 0;
        position: relative;
        margin-bottom: 12px;

        @include responsive(screen-phone) {
            border-bottom: 1px solid #e2e2e2;
        }

        a {
            color: $gray-dark1;
            text-decoration: none;
            display: block;

            h5 {
                float: left;
                width: 140px;
                color: #657285;
                text-transform: none;
                @include MyriadProBold;
                font-size: 15px;
                line-height: 24px;
                margin: -7px 0 0 24px;

                @include responsive(screen-phone) {
                    width: 70%;
                }

                @include responsive(screen-desktop) {
                    font-size: 16px;
                }
            }

            &:hover {
                h5, span {
                    text-decoration: underline;
                }
            }

            span {
                font-size: 14px;
                @include MyriadProBold;
                display: block;
                margin-top: -2px;

                @include responsive(screen-phone) {
                    margin-top: 5px;
                }
            }
        }
    }

    .icon {
        color: $highlight-color;
        font-size: 35px;
    }

    .img-wrap {
        float: left;
        width: 44px;
        height: 44px;
        overflow: hidden;
    }
}


.list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 20px;
    font-family: sans-serif;
    font-weight: bold;
    text-transform: uppercase;

    a {
        color: $gray-dark1;
        text-decoration: none;
        display: block;

        &:hover {
            color: $gray;
        }
    }
}

.social-media {
    list-style: none;
    margin: 25px 0 0 0;
    padding: 0;
    font-size: 0;

    li {
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;

        &:first-child {
            margin-left: 0;
        }

        a {
            display: block;
            text-align: center;
            border: 1px solid $white;
            border-radius: 30px;
            height: 32px;
            width: 32px;
            overflow: hidden;
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
            }

            &:hover {
                background-color: $white;

                img {
                    bottom: 0;
                    top: auto;
                }
            }
        }
    }
}

.divider {
    color: white;
    background: $brand-blue-light;
    height: 80px;
    line-height: 80px;
    padding-left: 32px;
    font-size: 21px;
    @include MyriadPro;
    display: block;
    margin: 0 0 30px 0;
    text-align: center;
    position: relative;

    @include responsive(screen-phone) {
        font-size: 18px;
        padding-left: 24px;
        text-align: left;
    }

    &:after {
        @include ab-center;
        left: auto;
        height: 100%;
        width: 107px;
        //background: #0c2c4e;
    }

    &:before {
        @include ab-center;
        left: auto;
        right: 32px;
        z-index: 2;
    }

    &.courses {
        &:before {
            background: url(../img/layout/icons/svg/school.svg) no-repeat 0 3px;
            background-size: 44px;
            width: 44px;
            height: 44px;
            top: -2px;
        }
    }

    &.webinars {
        &:before {
            background: url(../img/layout/icons/svg/video-lecture.svg) no-repeat 0 4px;
            background-size: 44px;
            width: 44px;
            top: 15px;
        }
    }

    &.journeys {
        &:before {
            background: url(../img/layout/icons/svg/trajectory.svg) no-repeat 0 4px;
            background-size: 40px;
            width: 40px;
            top: 15px;
        }
    }

    &.podcasts {
        &:before {
            background: url(../img/layout/icons/svg/headphones.svg) no-repeat 0 4px;
            background-size: 40px;
            width: 40px;
            top: 15px;
        }
    }
}

.course-list-item {
    position: relative;
    margin-bottom: 30px;
    display: block;
    text-decoration: none !important;
    transition: all 200ms ease;
    min-height: 345px;

    &.journey {
        .educator {
            /*margin-left: 12px;
            border-left: 1px solid #3c3c3c;
            padding-left: 12px;*/
        }
    }

    @include responsive-custom(1px, 540px) {
        min-height: 375px;
    }

    @include responsive(screen-desktop) {

        &:active,
        &:hover:active {
            transform: translateY(0px);
            box-shadow: 0px 7px 14px -6px rgba(0,0,0,0);

            .description {
                background-color: #ffffff;
            }
        }

        &:hover {
            text-decoration: none;
            transform: translateY(-5px);
            box-shadow: 0px 7px 14px -6px rgba(0,0,0,0.3);

            .description {
                background-color: #ffffff;
            }

            .name {
                &:after {
                    height: 100%;
                }
            }

            .segment {
                &.s8 {
                    background-color: #16A287;
                }

                &.s3 {
                    background-color: #CC701E;
                }

                &.s1 {
                    background-color: #D7AF0D;
                }

                &.s4 {
                    background-color: #2E86C1;
                }

                &.s11 {
                    background-color: #CD4435;
                }

                &.s12 {
                    background-color: #263544;
                }

                &.s10 {
                    background-color: #854D9C;
                }

                &.s6 {
                    background-color: #219452;
                }

                &.s6 {
                    background-color: #219452;
                }

                &.s13 {
                    background-color: #6e4a09;
                }

                &.s14 {
                    background-color: #b70e5d;
                }

                &.s15 {
                    background-color: #367076;
                }

                &.s16 {
                    background-color: #3030c6;
                }
            }
        }
    }


    .segment {
        background: #2c5179;
        color: white;
        display: block;
        height: 34px;
        line-height: 34px;
        text-align: center;
        width: 100%;
        position: relative;

        @include responsive(screen-tablet) {
            font-size: 12px;
            text-align: left;
            padding-left: 40px;
        }

        &.s8 {
            background-color: #1abc9c;
        }

        &.s3 {
            background-color: #e67e22;
        }

        &.s1 {
            background-color: #f1c40f;
        }

        &.s4 {
            background-color: #0B0B61;
        }

        &.s11 {
            background-color: #e74c3c;
        }

        &.s12 {
            background-color: #34495e;
        }

        &.s10 {
            background-color: #9b59b6;
        }

        &.s6 {
            background-color: #27ae60;
        }

        &.s13 {
            background-color: #956b1f;
        }

        &.s14 {
            background-color: #EA2B84;
        }

        &.s15 {
            background-color: #45979f;
        }

        &.s16 {
            background-color: #0B0B61;
        }

        .segment-img {
            width: 21px;
            @include ab-center;
            top: 3px;
            right: auto;
            left: 12px;
        }
    }

    .name {
        position: relative;
        background-color: $brand-blue-light;
        display: block;
        line-height: 100px;
        height: 100px;
        text-align: center;
        margin: 0;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 0;
            //background-color: #455265;
            background: -webkit-radial-gradient(center, ellipse, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0) 80%);
            background: radial-gradient(ellipse at bottom, rgba(#455265, 0.7) 0%,rgba(0,0,0,0) 100%);
            transition: all 120ms ease;
        }

        h2 {
            position: relative;
            z-index: 1;
            color: white;
            width: 100%;
            vertical-align: middle;
            word-break: break-word;
            display: inline-block;
            @include MyriadProBold;
            font-size: 20px;
            text-decoration: none;
            margin: 0;
            padding: 10px;

            &.smaller {
                font-size: 19px;

                @include responsive(screen-tablet) {
                    font-size: 18px;
                }
            }

            @include responsive(screen-tablet) {
                font-size: 18px;
            }
        }
    }

    .description-wrapper {
        p {
            overflow: hidden;
            position: relative;
            max-height: 122px;
            text-align: justify;
        }
    }

    .description {
        padding: 16px 12px;
        border-left: 1px solid #f0f0f0;
        border-right: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
        background-color: #eef7fa;
        transition: all 300ms ease;

        @include responsive(screen-tablet) {
            height: 265px;
        }

        @include responsive(screen-desktop) {
            height: 245px;
        }

        .summary {
            clear: both;
            overflow: hidden;
            margin-bottom: 20px;
            line-height: 17px;

            .duration {
                float: left;
            }

            .course-status {
                position: relative;
                float: right;
                text-align: right;
                color: #3c3c3c;
                font-size: 12px;
                font-weight: 700;
                text-transform: uppercase;

                &:before {
                    content: '';
                    position: absolute;
                    left: -10px;
                    top: 50%;
                    margin-top: -3px;
                    height: 5px;
                    width: 5px;
                    border-radius: 50%;
                }

                &.course-status--not-started {
                    &:before {
                        background-color: #afafaf;
                    }
                }

                &.course-status--in-progress {
                    &:before {
                        background-color: #12c812;
                    }
                }

                &.course-status--finished {
                    &:before {
                        background-color: #2989ff;
                    }
                }
            }
        }

        .duration, .educator {
            color: #3c3c3c;
            line-height: 17px;
        }

        .educator {
            display: block;
        }

        b {
            @include MyriadProBold;
        }

        p {
            text-decoration: none;
            color: #393d40;
            font-size: 14px;
            line-height: 24px;
            margin: 20px 0 0;
            margin-top: 10px;
            word-break: break-word;
        }
    }

    .tags {
        position: relative;
        padding-left: 32px;
        margin-top: 12px;
        padding: 0 0 24px 32px;

        &:before {
            @include ab-center;
            right: auto;
            background: url(../img/layout/icons/svg/tags.svg) no-repeat 0 3px;
            background-size: 21px;
            width: 21px;
        }

        .tag {
            color: #7e7e7e;
            float: left;
            @include MyriadProBold;
            font-size: 13px;
            margin: 0 8px 4px 0;
            position: relative;

            &:after {
                position: absolute;
                content: ",";
                @include MyriadProBold;
                font-size: 13px;
                color: #7e7e7e;
                right: -5px;
            }

            &:last-child {
                margin: 0;

                &:after {
                    display: none;
                }
            }
        }
    }

    &.journey, &.Webinar, &.Podcast {
        h2 {
            max-width: 244px;

            @include responsive(screen-tablet) {
                max-width: 150px;
            }

            @include responsive(screen-phone) {
                max-width: 220px;
            }
            /*&:before {
                content: "";
                background: url(../img/layout/icons/svg/trajectory.svg) no-repeat 0 3px;
                background-size: 27px;
                width: 30px;
                height: 30px;
                position: absolute;
                top: -3px;
                right: auto;
                left: -24px;
            }*/
        }

        h3 {
            @include MyriadProBold;
            font-size: 14px;
            margin: 0 0 10px 0;
        }
    }

    &.Webinar {
        h2:before {
            background: url(../img/layout/icons/svg/video-lecture.svg) no-repeat 0 3px;
            background-size: 27px;
        }
    }

    &.Podcast {
        h2:before {
            background: url(../img/layout/icons/svg/headphones.svg) no-repeat 0 3px;
            background-size: 27px;
        }
    }
}


.brand-new {
    background: url(../img/layout/icons/svg/new.svg) no-repeat 0 3px;
    top: -10px;
    width: 35px;
    height: 41px;
    position: absolute;
    right: 5px;
    display: block;
}

.brands-list {
    overflow: hidden;
    margin: 0 0 26px 0;

    .brand-item {
        margin-top: 30px;
        background: #f6f8f8;
        border: 1px solid #f5f5f5;
        position: relative;
        padding: 20px;

        p {
            font-size: 14px;
            margin-bottom: 20px;

            @include responsive(screen-from-tablet) {
                min-height: 120px;
            }
        }

        figure {
            text-align: center;
            position: relative;
            margin: 0 0 20px 0;
            height: 165px;

            &:after {
                @include ab-center;
                top: auto;
                border: none;
                color: white;
                height: 1px;
                background: black;
                width: 100%;
                background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 350, from(#657184), to(#f2f4f7));
            }

            img {
                position: absolute;
                top: 23px;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
            }
        }

        .btn-secondary {
            display: block;
            margin-left: 0;
        }

        .courses {
            display: inline-block;
            font-size: 14px;
            line-height: 1.4;
            color: $gray-dark1;
            width: 100%;
            text-transform: lowercase;
            font-family: sans-serif;
        }

        .go-to-list:hover {
            background-color: $black;
            color: $white;

            .icon {
                color: $white;
            }
        }
    }
}
