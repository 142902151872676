﻿.sign-on {
  .btn-secondary {
    margin-left: 0;
  }

  a,
  label {
    @include MyriadPro;
    font-size: 16px;
  }

  .noa-form {
    label {
      padding-left: 0;
      line-height: 40px;
    }

    .checkbox {
      padding-left: 21px;

      input {
        height: auto;
      }

      label {
        padding-left: 12px;
        line-height: 100%;
      }
    }

    .btn-secondary {
      line-height: 3px;
    }
  }

  h4 {
    font-size: 24px;
    padding-bottom: 24px;
  }

  .forgot-pass {
    float: right;
    margin-top: -39px;
    position: relative;
    display: block;
  }
}

.c-login {
  background-color: white;
  padding: 2em;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin: 0 auto;
  max-width: 750px;
  position: relative;

  .c-login__form {
    max-width: 420px;
  }
}

.sk-c-login {
    background-color: white;
    padding: 2em;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    margin: 0 auto;
    max-width: 750px;
    position: relative;

    .sk-c-login__form {
        
    }
}

.c-links {
  margin: 1em auto 0;
  max-width: 500px;
}
.c-validation-summary {
  margin-top: 2em;
  ul {
    margin-bottom: 0;
  }
}

/* Hide/rearrange for smaller screens */
@media screen and (max-width: 767px) {
    .header-centered-mobile {
        text-align: center;
    }

    .content-centered-mobile {
        display: inline-block !important;
    }

    .margin-right-mobile {
        margin-right: 20px !important;
    }

    .margin-bottom-mobile {
        margin-bottom: 20px !important;
    }

    .u-margin-left-20-mobile {
        margin-left: 20px !important;
        margin-right: 0px !important;
    }
}

/* Hide/rearrange for smaller screens */
@media screen {
    .sk-header-centered-mobile {
        text-align: center !important;
    }

    .sk-content-centered-mobile {
        display: inline-block !important;
    }

    .sk-margin-right-mobile {
        margin-right: 20px !important;
    }

    .sk-margin-bottom-mobile {
        margin-bottom: 20px !important;
    }

    .sk-u-margin-left-20-mobile {
        margin-left: 20px !important;
        margin-right: 0px !important;
    }
}
