.user-area {
	display: block;
	text-align: right;
	font-size: 0;
	line-height: $navbar-height;
    float: right;
    position: relative;
    @include MyriadProBold; 
	a {
		color: $black;
        font-size: 15px;
        line-height: 41px;
        margin-top: 5px;
        &.active {
            font-weight: bold;
        }
	}
	> * {
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
	}
    .noa-search {
        position: absolute;
        right: 0;
        top: 54px;
    }
    #searchForm {
        display: none;
        @include responsive(screen-from-tablet) {
            display: block;
            width: 234px;
        }
    }
	.user-img {
		display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
        width: 40px;
        height: 40px;
        overflow: hidden;
        line-height: 50px;
        text-align: center;
        border: 1px solid $gray-light4;
        border-radius: 50%;
        font-size: 0;
        margin-top: 5px;
        position: relative;
		img {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            margin: auto;
		}
	}
	.user {
		margin-left: 80px;
	}
	.name {
        @include MyriadPro;
        font-weight: normal;
		font-size: 14px;
		line-height: normal;
		display: inline-block;
		vertical-align: middle;
		.btn {
			background: none;
			border: 0;
            @include MyriadPro;
			padding: 0;
			vertical-align: top;
            min-width: 80px;
            margin-top: 3px;
			&:hover, &:focus, &:active {
				background: none;
			}
		}

	}
	.caret {
		border-width: 8px 8px 0 8px;
		margin-top: -4px;
	}
	.noa-btn.btn.no-bd {
		border: 0;
		&:active, &:hover {
			background: none;
			color: $highlight-color;
		}
	}
}