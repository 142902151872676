.facets-wrap {
    margin-bottom: 2em;

    .filter-toggle {
        padding: 20px 20px 0 20px;
        font-size: 18px;
        margin-bottom: 5px;
    }

    .facet {
        background: #f6f8f8;
        border: 1px solid #ccc;
        height: 48px;
        padding: 0 12px;
        cursor: pointer;
        transition: all .3s;
        margin-bottom: 0.5em;
        position: relative;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        user-select: none;

        &:hover {
            background: #e8e8e8;
        }

        h5 {
            @include MyriadPro;
            font-size: 16px;
            line-height: 48px;
            text-transform: none;
            font-weight: normal;
            display: block;
            width: 100%;

            &:after {
                background: url(../img/layout/icons/svg/keyboard-right-arrow-button.svg) no-repeat 0 3px;
                transform: rotate(90deg);
                background-size: 12px;
                @include ab-center;
                left: auto;
                right: 26px;
                width: 12px;
            }

            &.toggled:after {
                transform: rotate(270deg);
                right: 0;
            }
        }

        ol {
            background: white;
            z-index: 10;
            border: 1px solid #ccc;
            border-top: 0;
            position: relative;
            overflow: hidden;
            margin: -1px -13px 0 -13px;
            display: none;
            &:after {
                @include ab-center;
                bottom: -18px;
                top: auto;
                display: block;
                border: none;
                color: white;
                height: 1px;
                background: black;
                width: 100%;
                background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 350, from(#657184), to(#f2f4f7));
                padding: 0 20px;
            }

            li {
                position: relative;
                overflow: hidden;
                margin: 0 0 0 -32px;
                transition: all .3s;
                display: block;

                &:hover {
                    background: whitesmoke;
                }

                .number-trainings {
                    text-transform: lowercase;
                    color: #777;
                    margin-left: 40px;
                    width: 100%;

                    &.right {
                        @include ab-center;
                        left: auto;
                        text-align: right;
                        width: 30px;
                    }
                }
            }
        }

        label {
            cursor: pointer;
            margin: 0;
            width: 100%;
            padding-left: 12px;
            font-size: 14px;
            span {
                @include MyriadPro;
                font-size: 14px;
                color: #393d40;
                line-height: 38px;
            }

            .count {
                @include MyriadProBold;
                font-size: 14px;
            }
        }

        .disabled {
            color: $gray-dark1;
        }

        p {
            font-size: 12px;

            &:last-child {
                margin: 0;
            }
        }

        .price {
            font-size: 16px;
        }

        &.subscribe {
            background: $facet-highlight;
            border-top: 0;
            color: $white;

            p {
                font-size: 16px;
            }

            .noa-btn.btn-default.buy {
                padding: 0 35px;
            }
        }

        & + .show-more-wrapper {
            margin-top: -20px;
        }

        .show-more {
            margin-top: 20px;
            color: $black;
            display: block;

            .icon {
                left: 0;
            }
        }
    }
}

.no-results {
    margin-bottom: 120px;
}

.show-more-wrapper {
    background: $gray-light3;
    padding: 20px;
}

.c-facets-selected__item {
    background: #2c5179;
    color: white;
    padding: 0.2em 1em;
    margin-bottom: 5px;
    border: 1px solid #0c2c4e;
    font-size: 0.9em;
    text-transform: uppercase;
    display: block;
    position: relative;
    transition: 0.2s ease-in-out all;
    @include MyriadPro;
    .c-facets-selected__description {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background: white;
            top: 50%;
            left: 0;
            transform: scaleX(0);
            transform-origin: center left;
            transition: transform 0.5s cubic-bezier(.55, 0, .1, 1);
            z-index: 1;
        }
    }

    &.is-delete-selected .c-facets-selected__description:after {
        transform: scaleX(1);
    }

    &:hover {
        background: #0c2c4e;
    }

    .c-facets-selected__remove {
        float: right;
        position: absolute;
        top: 50%;
        z-index: 2;
        transform: translateY(-50%);
        right: 0.5em;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        transition: 0.2s ease-in-out all;
        @include MyriadProBold;
        &:hover {
            background: #222;
            color: #fff;
            cursor: pointer;
        }
    }
}
