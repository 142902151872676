﻿.c-banner {
  position: relative;
  width: 100%;
  margin: 0 auto;
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 2;
  overflow: hidden;

  &.c-banner--large {
    .c-banner__content {
      left: 0;
      bottom: 0px;
      width: 100%;

      @include responsive-custom(0, 1800px) {
        bottom: 40%;
        @include transform(translateY(50%));
      }

      .c-banner__title--big {
        @include responsive-custom(1200px, 1800px) {
          font-size: 70px;
        }
      }
    }

    .c-banner__props {
      vertical-align: middle;
      display: inline-block;
      width: calc(100% / 2 - 2px);

      @include responsive-custom(0px, 1024px) {
        width: 100%;
      }

      .banner-girl {
        width: auto;
        max-width: 100%;

        @include responsive-custom(0px, 1024px) {
          display: none;
        }
      }

      .banner-boy {
        position: relative;
        top: 48px;

        @include responsive-custom(0px, 1024px) {
          display: none;
        }

        @include responsive-custom(1200px, 1560px) {
          width: 380px;
          top: 60px;
        }

        @include responsive-custom(1024px, 1200px) {
          width: 320px;
          top: 70px;
        }
      }
    }
  }

  &.c-banner__zakelijk {
    height: 875px;
    background-image: url("../../../img/layout/zakelijk-banner-bg.jpg");

    @include responsive-custom(1400px, 1800px) {
      height: 0;
      padding-top: 48%;
    }

    @include responsive-custom(1024px, 1400px) {
      height: 0;
      padding-top: 55%;
    }

    @include responsive-custom(0px, 1024px) {
      height: 660px;
      padding-top: 0;
    }

    .c-banner__content {
      bottom: 40%;
      @include transform(translateY(50%));
    }

    @include responsive-custom(541px, 800px) {
      padding-top: 0;
      height: 670px;
    }

    .c-button {
      &:nth-of-type(2) {
        margin-left: 20px;
      }

      @include responsive-custom(1px, 440px) {
        display: block;

        &:nth-of-type(2) {
          margin-left: 0px;
          margin-top: 10px;
        }
      }
    }
  }

  &.c-banner__home {
    height: 875px;
    background-image: url("../../../img/layout/header-banner-bg.jpg");

    .mob-icon {
      display: none;

      @include responsive-custom(540px, 1024px) {
        display: block;
        position: absolute;
        z-index: -1;
        opacity: 0.4;
      }

      &.mob-icon-1 {
        top: -40px;
        right: 30%;
      }

      &.mob-icon-2 {
        top: 80px;
        right: 90px;
      }

      &.mob-icon-3 {
        bottom: -50px;
        right: 20%;
      }

      &.mob-icon-4 {
        bottom: 60px;
        right: 40%;
      }
    }

    @include responsive-custom(1024px, 1800px) {
      height: 0;
      padding-top: 53%;
    }

    @include responsive-custom(0px, 1024px) {
      height: 660px;
      padding-top: 0;
    }
  }

  &.c-banner__med {
    height: 340px;
    background-position: top right;
    background-image: url("../../../img/layout/OA_Header_Banner.jpg");
    display: flex;
    align-items: center;

    @include responsive-custom(0, 540px) {
      height: 500px;

      .c-banner__content {
        bottom: 40px;
      }
    }

    .c-button {
      @include responsive-custom(0, 580px) {
        float: none;
      }
    }
  }

  .c-banner__content {
    position: absolute;
    right: 20%;
    width: 100%;
    bottom: 16%;

    @include responsive-custom(0, 1440px) {
      bottom: 40px;
    }

    .c-banner__title,
    p {
      display: block;
      color: currentColor;
    }

    .c-banner__title {
      line-height: 1;
    }

    .c-banner__title--big {
      font-size: 105px;
      @include MyriadProBold;

      @include responsive-custom(1181px, 1440px) {
        font-size: 70px;
      }

      @include responsive-custom(0px, 1024px) {
        br {
          display: none;
        }
      }

      @include responsive-custom(541px, 1180px) {
        font-size: 55px;
      }

      @include responsive-custom(0px, 540px) {
        font-size: 40px;

        span {
          display: block;
        }
      }
    }

    .c-banner__title--med {
      @include MyriadPro;
      font-size: 48px;
      margin: 0;
      text-transform: inherit;

      @include responsive-custom(381px, 541px) {
        font-size: 40px;
      }
    }

    p {
      font-size: 23px;
      line-height: 1.3em;
      margin-bottom: 40px;

      @include responsive-custom(0px, 541px) {
        font-size: 20px;
      }

      span {
        @include MyriadProBold;
      }

      &:not(:nth-of-type(1)) {
        margin-bottom: 30px;
      }
    }

    .c-button {
      @include responsive-custom(0, 800px) {
        float: none;
      }
    }

    .c-alb {
      h2,
      p {
        color: #000000;
        text-transform: initial;
      }

      h2,
      span {
        @include MyriadProBold;
      }

      h2 {
        font-size: 30px;
        color: $brand-red;
        margin: 0;

        a {
          color: currentColor;
          text-decoration: underline;
          @include MyriadPro;
        }
      }

      p {
        margin: 0;
        font-size: 24px;

        span {
          color: #0095dd;
        }
      }
    }
  }

  &.c-banner__small {
    height: 250px;
    background-position: center right;
    background-image: url("../../../img/layout/OA_Header_Banner.jpg");
    display: flex;
    align-items: center;

    @include responsive-custom(0, 541px) {
      height: 300px;
    }

    .c-banner__content {
      @include responsive-custom(541px, 999999px) {
        bottom: 50%;
        @include transform(translateY(50%));
      }
    }
  }
}
