﻿.lightbox {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0; 
    top: 0;
    z-index: 2000;
    overflow-y: scroll; 
    -webkit-overflow-scrolling: touch; 
    .lightbox-bg {
        display: table;
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 99;
        background: #000000;
        opacity: .5;
        overflow: hidden;
    }
    > div {
        display: table-cell;
        vertical-align: middle;
        position: fixed;
        z-index: 100;
        height: 100%;
        padding: 0 10px;
        @include responsive(screen-from-tablet) {
            position: relative;
            padding: 0;
        }
        @include responsive(screen-phone) {
            padding: 0;
        }
        .content {
            background: #ffffff;
            position: fixed;
            width: 100%;
            margin: 0 auto;
            height: 100%;
            overflow-y: auto;
            @include responsive(screen-from-tablet) {
                width: 700px;
                max-height: 790px;
                position: relative;
                height: auto;
            }   
            &.call-back .lightbox-header:before {
                content: "";
                background: url(../img/layout/icons/call-back-icon.png) no-repeat;
                width: 75px;
                height: 115px;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                right: 60px;
                @include responsive(screen-mobile) {
                    display: none;
                }
            }
            .lightbox-header {
                background: #ffffff;
                background: -moz-linear-gradient(top,  #ffffff 0%, #e9e7e7 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top,  #ffffff 0%,#e9e7e7 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom,  #ffffff 0%,#e9e7e7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e9e7e7',GradientType=0 ); /* IE6-9 */
                padding: 20px;
                position: relative;
                @include responsive(screen-from-tablet) {
                    padding: 34px;
                }
                &:after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 22px 27.5px 0 27.5px;
                    border-color: #eae9e9 transparent transparent transparent;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    margin-bottom: -21px;
                }
                p {
                    @include responsive(screen-from-tablet) {
                        max-width: 90%;
                    }
                }
            }
            .lightbox-content {
                padding: 20px;
                background: #ffffff; /* Old browsers */
                background: -moz-linear-gradient(top,  #ffffff 0%, #e9e7e7 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top,  #ffffff 0%,#e9e7e7 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom,  #ffffff 0%,#e9e7e7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e9e7e7',GradientType=0 ); /* IE6-9 */
                @include responsive(screen-from-tablet) {
                    padding: 50px 20px 20px 20px;
                }   
                .user-img {
                    margin-bottom: 20px;
                }                
            }
            h3 {
                text-transform: uppercase;
                margin-bottom: 20px;
                position: relative;
                font-size: 22px;
                @include responsive(screen-mobile) {
                    margin-bottom: 10px;
                }
            } 
            .close {
                position: absolute;
                right: 20px;
                top: 20px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                cursor: pointer;
                transition: background .25s;
                background: $highlight-color;
                z-index: 2;
                &:before {
                    background: url(../img/layout/icons/lightbox-close.png);
                    @include ab-center;
                    width: 16px;
                    height: 16px;
                }
                &:hover {
                    background: #4f566a;
                }
            }
            #CaptchaInputText {
                margin-top: 0;
            }
            .captcha {
                margin-top: 20px;
            }        
        }  
    }
    &.video {
        padding: 0 20px;
        @include responsive(screen-phone) {
            display: none !important;
        }
        > div {
            width: 100%;
        }
        .content {
            background: #333;
            width: 80%;
            max-height: 950px;
            padding-bottom: 20px;
            padding: 50px 10px 10px 10px;
            @include responsive(screen-tablet) {
                width: 90%;
            }
            .video-wrapper {
                position: relative;
            }
            .close {
                position: absolute;
                background: black;
                top: -38px;
                right: 0;
            }  
        }
    }
    &.profile-picture {
        .lightbox-content {
            overflow: hidden;
            min-height: 450px;
        }
        h2 {
            margin-bottom: 20px;
        }
        img {
            max-height: 250px;
            margin: 0 auto 20px auto;
            @include responsive(screen-phone) {
               margin-bottom: 20px;
            }
        }
        form {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            width: 250px;
            @include responsive(screen-phone) {
              margin-top: 16px;
            }
            .add-btn {
                text-align: center;
                display: block;
            }
            .btn-secondary {
                margin-left: 0;
                margin-top: 10px;
                width: 100%;
            }
        }
    }
}
