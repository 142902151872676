﻿.my-account {
    h2 {
        color: #393d40;
        margin-bottom: 30px;
        margin-top: 0;
        font-size: 24px;
        @include MyriadProBold;
    }
    .well {
        padding: 46px 0;
        &:nth-child(even) {
            background: #f6f8f8;
        }
        &:nth-child(odd) {
            background: white;
        }
    }
    .scrollable-content {
        padding-top: 32px;
    }
}

.profile-info, .assistant {
    .my-info-toggle {
        margin-bottom: 15px;
        &:after {
            background: url(../img/layout/icons/arrow-up.png);
        }
        &.toggled {
            &:after {
                background: url(../img/layout/icons/arrow-down.png);
            }
        }
    }
    .profile-pic {
        border: 8px solid white;
        box-shadow: 4px 3px 7px 0px rgba(0, 0, 0, 0.14);
        position: relative;
        width: 148px;
        height: 148px;
        margin: 30px auto;
        position: relative;
        @include responsive(screen-from-tablet) {
            background: whitesmoke;
            float: left;
            margin: 0;
        }
        img {
            max-width: 75%;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
        .profile-pic-edit {
            @include ab-center;
            left: auto;
            top: auto;
            width: 25px;
            height: 25px;
            background: white;
            z-index: 1;
            &:after {
                content: "";
                background: url(../img/layout/icons/profile-pic-edit.png);
                width: 15px;
                height: 14px;
                position: absolute;
                right: 0;
                bottom: 0;
                left: 5px;
                top: 2px;
                margin: auto;
            }
        }
    }
    .profile-summary {
        float: left;
        width: 100%;
        .cancel-subscription {
            text-decoration: underline; 
            color: $special-text-color;
        }
        @include responsive(screen-tablet) {
            padding-left: 40px;
        }
        @include responsive(screen-desktop) {
            padding-left: 20px;
        }
        .summary-list {
            float: left;
            width: 100%;
            @include responsive(screen-from-tablet) {
                width: 50%;
            }
            .summary-entry {
                float: left;
                width: 100%;
                position: relative;
                min-height: 60px;
                @include responsive(screen-phone) {
                    margin-bottom: 30px;              
                }
                &:after {
                    @include responsive(screen-phone) {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        top: 76px;
                        display:block;
                        height:1px;
                        width: 100%;
                        background: #dcdcdc;
                    }
                }
                span {
                    float: left;
                    @include MyriadProBold;
                    text-transform: uppercase;
                    font-size: 16px;
                    color: #393d40;
                    width: 100%;
                    margin-bottom: 10px;
                    @include responsive(screen-from-tablet) {
                        text-transform: none;
                        margin: 0;
                    }
                    @include responsive(screen-tablet) {
                        width: 120px;
                    }
                    @include responsive(screen-desktop) {
                        text-transform: none;
                        width: 150px;
                        min-height: 56px;
                    }          
                }
                p {
                    float: left;
                    font-size: 16px;
                    color: #393d40;
                    padding-right: 20px;
                     @include responsive(screen-tablet) {
                        float: left;
                        width: 100%;
                    }
                    @include responsive(screen-desktop) {
                        float: left;
                        width: 253px;
                    }
                    a .cancel-subscription {
                        color: green;
                    }
                    .cancel-subscription {
                        color: red;
                    }
                }
                input#CouponCode[readonly="True"]:focus {
                    outline: none;
                    box-shadow: none;
                    font-style: italic;
                }
            }
        } 
    }
    .my-info {
        @include responsive(screen-phone) {
            display: block;
        }
        @include responsive(screen-from-tablet) {
            display: block !important;
        }
    }
}

.active-trainings {
    .active-training {
        float: left;
        width: 100%;
        position: relative;  
        margin-bottom: 16px;
        .noa-btn.go {
            @include responsive(screen-phone) {
                margin: 10px 0 16px 0;
            }
        }
    }
    .active-journey {
        float: left;
        width: 100%;
        position: relative;  
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
        .journey-contents {
            background: #f6f8f8;
            padding: 16px 24px;
            @include responsive(screen-phone) {
                padding: 12px;
                overflow: hidden;
            }
            .item {
                height: 37px;
                margin-bottom: 16px;
                position: relative;
                &:last-child {
                    margin-bottom: 0;
                }
                .progress-bar {
                    width: 77.8%;
                    @include responsive(screen-phone) {
                        width: 100%;
                        margin-bottom: 10px;
                    }
                    @include responsive(screen-tablet) {
                        width: 76%;
                    }
                }
                .name {
                    float: left;
                    font-size: 16px;
                    line-height: 35px;
                    @include responsive(screen-phone) {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 86%;
                        display: inline-block;
                        white-space: nowrap;
                    }
                }
                .percentage {
                    float: right;
                    font-size: 16px;
                    margin: 1px 16px 0 0;
                    @include responsive(screen-phone) {
                        margin: 1px 16px 0 0;
                    }
                }
                .go {
                    float: right;
                    width: 144px;
                    @include responsive(screen-phone) {
                        float: left;
                        width: 100%;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
    .progress-bar {
        float: left;
        font-size: 16px;
        line-height: 35px;
        padding-left: 12px;
        border: 1px solid #9e9e9e;
        height: 37px;
        width: 220px;
        border-radius: 1px;
        background: white;
        position: relative;
        width: 100%;
    }
    .progress {
        @include ab-center;
        right: auto;
        height: 100%;
        background: #e3e3e3; 
        &._0 {
            width: 0;
        }
        &._5 {
            width: 5%;
        }
        &._10 {
            width: 10%;
        }
        &._15 {
            width: 15%;
        }
        &._20 {
            width: 20%;
        }  
        &._25 {
            width: 25%;
        } 
        &._30 {
            width: 30%;
        }   
        &._35 {
            width: 35%;
        }
        &._40 {
            width: 40%;
        }  
        &._45 {
            width: 45%;
        }
        &._50 {
            width: 50%;
        }
        &._55 {
            width: 55%;
        }
        &._60 {
            width: 60%;
        }
        &._65 {
            width: 65%;
        }
        &._70 {
            width: 70%;
        }
        &._75 {
            width: 75%;
        }
        &._80 {
            width: 80%;
        }     
        &._85 {
            width: 85%;
        }
        &._90 {
            width: 90%;
        }
        &._95 {
            width: 95%;
        }
        &._100 {
            width: 100%;
        }     
    }
    .name {
        position: relative;
        color: #393d40;
        @include responsive(screen-phone) {
            font-size: 15px;
        }
    }
    .percentage {
        color: #393d40;
        @include MyriadProBold;
        float: right;
        margin-right: 12px;
        position: relative; 
        @include responsive(screen-phone) {
            position: absolute;
            top: 1px;
            right: -9px;
        }
    }
}

.active-journeys {
    float: left;
    width: 100%;
    margin-top: 64px;
}
.no-trainings {
    font-size: 16px;
}
.show-more {
    text-align: center;
    margin-top: 8px;
    span {
        cursor: pointer;
        display: inline-block;
        text-transform: uppercase;
        @include MyriadProBold;
        font-size: 16px;
        position: relative;
        user-select: none;
        i {
            font-style: normal;
            display: inline-block;
        }
        b {
            display: none;
            font-weight: normal;
        }
        &:after {
            content: "";
            cursor: pointer;
            position: absolute;
            right: -28px;
            top: 6px;
            background: url(../img/layout/icons/svg/keyboard-right-arrow-button.svg) no-repeat 0 3px;
            transform: rotate(90deg);
            background-size: 12px;
            width: 16px;
            height: 16px;
        }
        &.toggled {
            margin: 0 0 20px 0;
            &:after {
                transform: rotate(270deg);
                top: 0;
            }
            i {
                display: none;
            }
            b {
                display: inline-block;
            }
        }
    }
}
.more-courses, .more-journeys {
    display: none;
}

.completed-trainings, .active-tranings {
    h2 {
        margin-bottom: 30px;
    }
    .no-trainings {
        color: white;
        position: relative;
        z-index: 2;
        a {
            float: none;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 14px;
            margin: 0;
            text-decoration: underline;
        }
    }
}
.completed {
    margin-bottom: 32px;
    overflow: hidden;
    .col-xs-12:last-child .completed-item {
        border-bottom: 0;
    }
    .completed-item {
        margin-bottom: 32px;
        overflow: hidden;
        @include responsive(screen-phone) {
            margin-bottom: 16px;
            overflow: hidden;
            padding-bottom: 16px;
            border-bottom: 1px solid #ccc;
        }
        .title-link {
            position: relative;
            display: block;
            &:hover {
                h4 {
                    text-decoration: underline;
                }
            }
            &:before {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                margin: auto;
                background: url(../img/layout/icons/svg/quality.svg) no-repeat 0 3px;
                background-size: 26px;
                height: 29px;
                width: 26px;
            }
            h4 {
                padding-left: 36px;
                color: #393d40;
                line-height: 26px;
                font-size: 21px;
                margin-right: 16px;
                margin-bottom: 12px;
            }      
        }
        .certificate {
            display: block;
            float: left;
            color: #393d40;
            border: 1px solid #393d40;
            @include MyriadProBold;
            font-size: 14px;
            height: 32px;
            line-height: 32px;
            margin-left: 32px;
            padding: 0 12px;
            position: relative;
            transition: all .3s;
            &:hover {
                &:before {
                    background: black;
                }
            }
            &:before {
                content: "";
                width: 32px;
                height: 32px;
                position: absolute;
                left: -32px;
                top: -1px;
                background: #393d40;
            }
            &:after {
                background: url(../img/layout/icons/svg/download.svg) no-repeat 0 3px;
                background-size: 17px;
                content: "";
                position: absolute;
                left: -25px;
                top: 4px;
                height: 29px;
                width: 29px;
            }
        }
    }
}

.assistant {
    -webkit-touch-callout: none;
    -webkit-user-select: none; 
    -khtml-user-select: none;
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
    h4 {
        font-size: 21px; 
        margin-bottom: 12px;
        float: left;
    }
    p {
        clear: both;
        float: left; 
        font-size: 16px;
        width: 60%;
        @include responsive(screen-desktop) {
            width: 70%;
        }
    }
    .reminder-block {
        margin-bottom: 32px;
        height: 90px;
    }
    .switch-bg, .switch-bg:after, .switch-bg:before {
        background: #393d40; 
    }
    .switch-bg.on, .switch-bg.on:after, .switch-bg.on:before {
        background: #4b9608;
    }
    .switch-bg {
        float: right;
        width: 100px;
        height: 18px;
        position: relative;
        cursor: pointer;
        margin-top: 4px;
        &:before, &:after {
            height: 18px;
            width: 18px;
            position: absolute;
            left: -9px;
            content: "";
            border-radius: 50%;
        }
        &:after {
            left: auto;
            right: -9px;
        }
        &.on {
            .switch {
                right: auto;
                left: -2px;
            }
            + p + .intensity, +.intensity {
                display: block;
            }
        }
        span {
            @include MyriadProBold;
            color: white;
            font-size: 12px;
            position: relative;
            z-index: 3;
            display: block;
            margin-top: -3px;
            &.uit {
                float: left;
                margin-left: 12px;
            }
            &.aan {
                float: right;
                margin-right: 12px;
            }
        }
        .switch {
            position: absolute;
            width: 40px;
            height: 19px;
            margin-top: -1px;
            margin-left: -2px;  
            z-index: 5;
            right: -2px;
            transition: all 2s;
            &:before, &:after { 
                height: 19px;
                width: 19px;
                position: absolute;
                left: -9px;
                content: "";
                border-radius: 50%;
            }
            &:after {
                border-left: 0;
                left: auto;
                right: -9px;
            }
            span {
                display: block;
                position: absolute;
                top: 7px;
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background: #989898; 
            }
        }
    }
    .switch:before, .switch:after, .switch {
        background: #cecece; 
    }
    .intensity {
        display: none;
        background: #e6e6e6;
        float: right;
        margin-top: -26px;
        margin-right: -8px;
        padding: 12px 6px 7px 10px;
        width: 30%;
        line-height: 20px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        width: 119px;
        label {
            cursor: pointer;
            @include MyriadProBold;
            input {
                margin: 7px 5px 0 0;
            }
        }
    }
}