﻿@font-face {
    font-family: 'MyriadPro';
    src: url('../../../fonts/MyriadPro/MyriadPro-Regular.eot');
    src: url('../../../fonts/MyriadPro/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
         url('../../../fonts/MyriadPro/MyriadPro-Regular.woff') format('woff'),
         url('../../../fonts/MyriadPro/MyriadPro-Regular.ttf') format('truetype'),
         url('../../../fonts/MyriadPro/MyriadPro-Regular.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'MyriadProBold';
    src: url('../../../fonts/MyriadProBold/MyriadPro-Bold.eot');
    src: url('../../../fonts/MyriadProBold/MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
         url('../../../fonts/MyriadProBold/MyriadPro-Bold.woff') format('woff'),
         url('../../../fonts/MyriadProBold/MyriadPro-Bold.ttf') format('truetype'),
         url('../../../fonts/MyriadProBold/MyriadPro-Bold.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@mixin MyriadPro {
    font-family: "MyriadPro", Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
@mixin MyriadProBold {
    font-family: "MyriadProBold", Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;
}