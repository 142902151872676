﻿.contact{
    background: #f5f5f5; /* Old browsers */
    background: -moz-linear-gradient(top,  #f5f5f5 0%, #ffffff 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #f5f5f5 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #f5f5f5 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    .faq-link {
        color: $brand-primary;
    }
    .noa-form {
        position: relative;
        z-index: 1;
        @include responsive(screen-desktop) {
            padding: 0 60px;
        }
        &:after {
            background: url("../img/layout/icons/contact-form-bg.png") no-repeat;
            @include ab-center;
            width: 272px;
            height: 235px;
            z-index: -1;
            @include responsive(screen-desktop) {
                left: -40px;
                right: auto;
            }
        }
        .btn-secondary {
            margin: 20px auto; 
            min-width: 220px;
            text-align: center;
            display: block;
        }
        .error.box{
            margin-bottom: 20px;
            padding: 10px;
        }
        .captcha {
            margin-bottom: 20px;
        }
    }
    .well.contact:before {
        border-color: #fcfcfc transparent transparent transparent;
    }
}