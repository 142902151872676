﻿.c-overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 100;
    background-color: rgba(0,0,0,0.8);
    visibility: hidden;
    opacity: 0;
    transition: all 300ms ease;

    &.is-active {
        opacity: 1;
        visibility: visible;

        .c-modal {
            opacity: 1;
            transition-delay: 300ms;
            @include transform(translateY(-50%));
        }
    }
}

.c-modal {
    position: relative;
    display: block;
    max-width: 600px;
    width: 96%;
    margin: 0 auto;
    top: 50%;
    transition: all 300ms cubic-bezier(0.3, 1.1, 0.5, 1.2);
    opacity: 0;
    @include transform(translateY(-40%));
    user-select: none;

    &.is-active { 
        .c-modal__header,
        .c-modal__body {
            filter: blur(3px);
            -webkit-filter: blur(3px);
        }

        .loader {
            opacity: 1;
            visibility: visible;
        }
    }

    .c-modal__header,
    .c-modal__body {
        padding: 50px;
        width: 100%;
        transition: all 300ms ease;

        @include responsive-custom(0, 660px) {
            padding: 30px;
        }
    }

    .c-modal__header {
        background-color: #ffffff;

        h1 {
            @include MyriadProBold;
            font-size: 28px;
            color: $brand-blue;
        }

        p {
            margin: 30px 0 0;
            font-size: 16px;
            color: rgba($brand-blue, 0.5);
        }
    }

    .c-modal__body {
        background-color: #f6f8f8;
    }

    .form {
        margin: 0;
        background-color: transparent;
        padding: 0;
        box-shadow: none;

        button {
            margin: 0;

            &:not(:nth-of-type(1)) {
                margin-left: 20px;

                @include responsive-custom(0, 420px) {
                    margin-left: 0;
                    margin-top: 20px;
                }
            }
        }

        .form-group {
            &:nth-of-type(1) {
                padding-top: 0;
            }
        }
    }
}


.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: rgba(255,255,255, 0.8);
    transition: all 300ms ease;
    opacity: 0;
    visibility: hidden; 

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50px;
        @include transform(translateY(-50%) translateX(-50%));
    }
}