﻿.o-error {
    padding: 100px 0;
    background-color: #e0e0e0;


    .course-list-item {
        .segment {
            font-size: 16px;
        }

        .description {
            padding: 30px;
            background-color: #ffffff;

            @media screen and (min-width: 768px) {
                height: auto;
                min-height: 335px;
            }

            .c-button {
                margin-top: 20px;
            }
        }
    }

    .scrollable-content { 
        background: transparent;  
    }
}
