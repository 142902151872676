/* --- Redesign colors 2020 ---*/

//general colors
$color-primary: #4c4184;
$color-primary-light: #8e87b1;
$color-secondary: #ffcd00;
$white-smoke: #f2f2f2;
$white-smoke-light: #f8f8f8;
$lavender-gray: #b7b3ce;
$cadet-blue: #6399ae;

$white: #ffffff;
$black: #000000;
$error-color: #a50034;
$green-color: #31b954;

//background colors
$footer-background-color: $white-smoke;
$background-color-primary: $white;
$background-color-secondary: $white-smoke-light;

//progress bar
$progress-bar-background-color: $lavender-gray;
$progress-bar-border-color: $color-primary-light;

//toggle, checkboxes, radio buttons
$checkbox-color: $cadet-blue;
$radiobutton-color: $cadet-blue;

//forms, errors
$invalid-color: $error-color;
$valid-color: $green-color;

$text-color-primary: #19191a;
$font-size-base: 16px;

/* --- --- */

//Previous colors
$brand-red: #e83456;
$brand-blue: #0d2c44; //445f75
$brand-blue-light: #0d2c44;
$brand-yellow: #f9b100;
$brand-blue-gray: #b8bbd7;

//Custom colors
$highlight-color: #757896;
$highlight-color-hover: #0a94cc;
$facet-highlight: #657285;
$highlight-color-light: #dcf1fa;
$confirmation-border-color: #dddddd;
$active-border: #767997;
$search-bar-color: #595960;
$default-heading: #91a4b8;
$anchor: #46a5f7;
$link-color: #9194b5;
$border-color: #7e819d;
$brand-extra-blue: #586d8a;
$special-text-color: #647186;
//Buckaroo colors
$idle: #fbb126;

//Segment colors
$coach: #6967ba;
$effectiviteit: #09948f;
$communicatie: #b32a06;
$hr: #5ead42;
$bedrijfskunde: #5642c8;
$project: #e67a39;
$sales: #49abe8;
$it: #af426b;

//Business Theme

$businessTheme: #0281c3;
$businessThemeLight: #49abe8;

//Simple colors
$white: #ffffff;
$black: #000000;

//50 shades of gray

$gray-light: #f8f8f8;
$gray-light1: #f6f6f6;
$gray-light2: #f2f2f2;
$gray-light3: #eeeeee;
$gray-light5: #e0e1e2;
$gray-light4: #dddddd;
$gray: #bbbbbb;
$gray-normal: #ccc;
$gray-dark1: #777777;
$gray-dark2: #555555;
$gray-dark3: #444444;
$gray-dark4: #2c2b2b;
$gray-dark5: #1f2430;
$gray-form-border: #a8a8a8;

//Error
$error-color: #f54a4a;

//Buttons
$continue-color: #f88735;
$start-color: #2faa14;

//separator variables
$layout-separator-small: 20px;
$layout-separator-medium: 30px;
$layout-separator-large: 45px;
$layout-separator-xlarge: 65px;

//borders
$input-border-radius: 7px;
$buttons-border-radius: 3px;
$checkbox-border-radius: 4px;

//header
$header-height: 100px;
$header-height-small: 90px;

//tabs
$tabs-height: 60px;

//checkboxes
$checkbox-size: 20px;

//course item
$segment-height: 36px;

//radio buttons
$radio-size: 15px;
