﻿.c-footer {
  overflow: hidden;
  background-color: $footer-background-color;
  padding-top: 75px;

  .container {
    width: 100%;
  }

  &.c-footer--fixed {
    .c-footer--fixed-holder {
      position: fixed;
      height: 370px;
      width: 100%;
      z-index: 1;
      bottom: 0;
      left: 0;

      @include responsive-custom(1px, 1020px) {
        position: relative;
        height: auto;
      }
    }

    &:before {
      content: "";
      position: relative;
      display: block;
      height: 370px;

      @include responsive-custom(1px, 1020px) {
        display: none;
      }
    }
  }

  &.c-footer--default {
    position: relative;
    width: 100%;
    background-color: $footer-background-color;
    padding: 70px 0 0;

    @include responsive-custom(1px, 540px) {
      padding: 40px 0;
    }
  }

  h2,
  a {
    display: block;
    color: $text-color-primary;
  }

  h2 {
    line-height: 1.3em;

    @include responsive-custom(1px, 560px) {
      font-size: 20px;
    }

    &:not(:first-child) {
      font-size: 22px;
    }

    a {
      line-height: 1;
      display: inline-block;
      text-decoration: underline;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    @include clearfix;

    li {
      float: left;
      width: calc(100% / 2);

      @include responsive-custom(0, 560px) {
        width: 100%;
        float: none;
        display: inline-block;
      }

      &:not(:first-child) {
        @include responsive-custom(0px, 560px) {
          margin-top: 10px;
        }
      }

      @include responsive-custom(0px, 560px) {
        display: block;
      }
    }

    a {
      &:not(:first-child) {
        margin-top: 10px;

        @include responsive-custom(1px, 560px) {
          margin-top: 10px;
        }
      }

      @include responsive-custom(1px, 560px) {
        padding-bottom: 10px;
      }
    }
  }

  .footer-logo {
    max-width: 180px;
    width: 100%;
  }

  .c-footer__container {
    display: flex;
    padding-bottom: 30px;

    @include responsive(screen-from-tablet) {
      border-bottom: 1px solid $text-color-primary;
    }

    @include responsive-custom(0, 1020px) {
      display: inherit;
    }
  }
  .c-footer__container-links {
    @include responsive(screen-from-tablet) {
      display: flex;
    }
  }

  .footer__newsletter {
    @include responsive-custom(0, 1020px) {
      padding-top: 30px;
    }
    .footer__newsletter-title {
      padding-bottom: 10px;
    }
  }
}

.c-footer__bottom {
  padding-bottom: 30px;

  .c-footer__bottom-content {
    color: $text-color-primary;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;

    .social-icons {
      display: flex;
      font-size: 20px;

      a:not(:first-child) {
        margin-left: 20px;
      }
    }

    @include responsive-custom(0, 767px) {
      flex-direction: column;
      align-items: center;

      .copyright {
        order: 2;
        margin-top: 16px;
      }
    }
  }
}
