﻿.privacy-container {
    padding: 20px;
    border: 4px solid #ebebeb;
}

.privacy-wrapper {
    padding: 20px;
}

.privacy-title {
    margin-bottom: 15px;
}


.c-cookie-bar {
    position: fixed;
    bottom: 100px;
    left: 50%;
    max-width: 500px;
    width: 100%;
    z-index: 1060;
    transform: translateX(-50%);
    text-align: left !important;
    background-color: rgba(0,0,0,0.9);
    padding: 25px;

    &.lightbox-enter-active:not(.is-not-animated),
    &.lightbox-leave-active {
        transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.lightbox-enter:not(.is-not-animated),
    &.lightbox-leave-to {
        transform: scale(1.1);
        opacity: 0;
    }

    .c-cookie-bar__content {
        color: #ffffff;
        margin-bottom: 1.5rem !important;
    }

    .c-cookie-bar__link, .c-cookie-bar__link:hover, .c-cookie-bar__link:active, .c-cookie-bar__link:visited {
        text-decoration: underline;
        color: #ffffff;
    }

    .c-cookie-bar__title {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 12px;
        font-weight: 300;
        color: #fff;
        font-family: inherit;
        letter-spacing: .05em;
    }

    .c-cookie-button {
        overflow: hidden;
        display: inline-block;
        width: auto;
        min-width: 160px;
        min-height: 32px;
        padding: 0 20px;
        border: none;
        background-color: #445d70;
        text-decoration: none;
        cursor: pointer;
        color: #fff;
    }
}

.c-cookie-bar__close {
    display: none;
}
