//Jumbotron
.jumbotron {
    text-shadow: 1px 1px 1px $black;

    &.noa-theme {
        background: url(../img/layout/jumbotron-bg.png) repeat-x 0 0;
        padding: 0;
        margin: 0;
    }

    &.banner {
        padding: 0;
        margin: 0;
        text-align: center;
        position: relative;
        width: 100%;
        height: 100%;
        background-size: cover;
        border-bottom: 7px solid $gray-light1;

        @include responsive(screen-phone) {
            border-bottom: 0;
            padding-bottom: 10px;
            overflow: hidden;
            background-size: 200%;
        }

        @include responsive(screen-tablet) {
            height: 427px;
        }

        @include responsive(screen-desktop) {
            height: 407px;
        }

        img {
            margin: 0 auto;
        }

        .container {
            .description-wrapper {
                @include responsive(screen-phone) {
                    &:after {
                        @include ab-center;
                        top: auto;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 10px 25px 0 25px;
                        border-color: #5d7591 transparent transparent transparent;
                    }
                }
            }

            .overlay {
                min-height: 400px;
                width: 100%;
                background: rgba(46, 80, 120, .69);
                position: relative;
                margin-top: -40px;

                @include responsive(screen-phone) {
                    margin-top: 74px;
                    margin-left: -30px;
                    width: 125%;
                    min-height: 0;
                    border-bottom: 20px solid white;
                    padding-left: 15px;
                    margin-bottom: -20px;
                    background: #5d7591;

                    &:before, &:after {
                        display: none;
                    }
                }

                @include responsive(screen-tablet) {
                    min-height: 420px;
                }

                &:before {
                    content: "";
                    display: block;
                    background: rgba(46, 80, 120, .69);
                    position: absolute;
                    height: 400px;
                    width: 8000px;
                    left: -8000px;
                    top: 0;

                    @include responsive(screen-tablet) {
                        height: 420px;
                    }

                    @include responsive(screen-phone) {
                        height: auto;
                    }
                }

                &:after {
                    content: "";
                    background: url("../img/content/bannercurve.png") no-repeat;
                    height: 400px;
                    width: 72px;
                    position: absolute;
                    top: 0;
                    right: -72px;

                    @include responsive(screen-tablet) {
                        height: 420px;
                        width: 72px;
                        right: -72px;
                    }

                    @include responsive(screen-phone) {
                        height: auto;
                    }
                }
            }

            .description {
                padding-top: 40px;

                @include responsive(screen-phone) {
                    padding-top: 25px;
                    padding-right: 30px;
                }

                h1 {
                    margin-bottom: 8px;
                    color: $white;
                    font-weight: normal;
                    @include MyriadProBold;

                    @include responsive(screen-from-tablet) {
                        font-size: 22px;
                    }

                    @include responsive(screen-phone) {
                        font-size: 18px;
                    }

                    @include responsive(screen-desktop) {
                        margin-top: 20px;
                    }
                }

                h2 {
                    color: $white;
                    font-weight: normal;
                    font-size: 16px;
                    text-transform: uppercase;
                    margin-bottom: 20px;

                    @include responsive(screen-phone) {
                        font-size: 12px;
                        line-height: 15px;
                    }
                }

                ul {
                    margin: 24px 0;

                    li {
                        position: relative;
                        display: block;
                        margin-bottom: 20px;
                        padding-left: 15px;
                        width: 100%;
                        line-height: 42px;
                        font-size: 20px;

                        @include responsive(screen-phone) {
                            font-size: 13px;
                            padding-left: 0;
                            margin-bottom: 10px;
                        }

                        span {
                            display: table-cell;
                            line-height: 24px;
                            vertical-align: middle;
                        }

                        &:before {
                            position: absolute;
                            width: 27px;
                            height: 27px;
                            content: '';
                            display: block;
                            background: url("../img/content/check-icon-banner.png") no-repeat;
                            top: 0;
                            left: -40px;

                            @include responsive(screen-phone) {
                                background: url("../img/content/check-icon-banner-mobile.png") no-repeat;
                                width: 15px;
                                height: 15px;
                                top: 5px;
                            }
                        }
                    }
                }

                .play-video {
                    color: white;
                    text-transform: uppercase;
                    line-height: 24px;
                    font-size: 16px;
                    @include MyriadProBold;
                    text-decoration: none;
                    position: relative;
                    padding-top: 10px;
                    margin-left: 55px;
                    display: block;
                    letter-spacing: .5px;

                    @include responsive(screen-tablet) {
                        margin-top: -5px;
                    }

                    @include responsive(screen-phone) {
                        display: none;
                    }

                    &:after {
                        content: "";
                        background: url("../img/content/play-video.png") no-repeat;
                        height: 67px;
                        width: 369px;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    &:hover {
                        &:after {
                            background: url("../img/content/play-video-hover.png") no-repeat;
                        }
                    }
                }

                .btn-white {
                    @include responsive(screen-phone) {
                        padding: 10px 24px;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.jumbotron.banner {
    height: 250px;
    background: url(../img/content/banner-my-account.png) no-repeat;
    background-position: 60% 100%;
    border-bottom: 0;
    position: fixed;
    top: 96px;
    background-size: cover;
    transition: all .3s;

    @include responsive-custom(0, 1020px) {
        top: -122px;
        height: 220px;
        margin-top: 0;
        display: none;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0,0,0,0.1);
        z-index: 1;
        background: rgba(46, 80, 120, .7);
    }

    h1 {
        color: white;
        @include MyriadPro;
        @include ab-center;
        font-size: 40px;
        text-transform: none;
        height: 40px;
        line-height: 40px;

        @include responsive(screen-phone) {
            font-size: 24px;
        }
    }

    .description-wrapper {
        &:after {
            display: none;
        }
    }

    &.sticky {
        top: 50px;
    }
}

.scrollable-content {
    //margin-top: 250px;
    width: 100%;
    height: 100%;
    background: white;
    position: relative;
    //margin-top: 250px;
    z-index: 1;

    @include responsive-custom(0, 1020px) {
        margin-top: 0;
        //padding-top: 100px;
    }
}

.intro {
    margin-bottom: 32px;
    overflow: hidden;

    p {
        font-size: 16px;
    }

    a {
        color: #333;
        font-size: 16px;
    }

    h4 {
        @include MyriadProBold;
    }
}

.usps {
    list-style: none;
    padding-left: 0;

    li {
        background: url(../img/layout/icons/svg/checked.svg) no-repeat 0 4px;
        background-size: 24px 24px;
        font-size: 16px;
        line-height: 32px;
        padding-left: 40px;
        min-height: 20px;
    }
}

.phone, .mail {
    position: relative;
    padding-left: 30px;
    display: block;
    margin-bottom: 7px;
    line-height: 24px;

    &:before {
        @include ab-center;
        background: url(../img/layout/icons/svg/call-answer.svg) no-repeat 0 3px;
        background-size: 16px;
        width: 16px;
        z-index: 1;
        right: auto;
    }
}

.mail:before {
    background: url(../img/layout/icons/svg/close-envelope.svg) no-repeat 0 3px;
}
